<template>
    <div class="table-responsive">
        
        <table class="table">
            <thead>
                <tr>                    
                    <th>Código</th>
                    <th>Puntos</th>
                    <th>Estado</th>
                    <th>Registro</th>                
                    
                </tr>
            </thead>
            <tbody>
                
                <tr v-for="(v, k) in data" :key="k" @click="link(v.id)" style="cursor: pointer;">                             
                    
                    <td>{{ v.id }}</td>                
                    <td>{{ v.point }}</td>                    
                    <td><span :class="'label ' + getCssStatus(v.status)">{{ v.status_label }}</span></td>
                    <td>{{ v.date_register }}</td>
                    
                </tr>
                
            </tbody>
            
        </table>
    </div>                    
</template>

<script>

import { getCssStatus } from "@/utils/Utils.js";
import router from "@/router";
export default {
    props: {
        data: Object
    },

    setup() {

        const link = (id) => {            
            router.push({ name: 'PointManagerDetail', params:{ id: id }})            
        }
        
        return { getCssStatus, link }
    }
    
}
</script>