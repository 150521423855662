<template>
  <div class="container">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <Breadcrumbs :data="dataViewAssetExchangeManagerDetail" />
    <Title :data="dataViewTitle" />

    <div class="row">
      <div class="col-lg-10">
        <div class="panel panel-primary">
          <div class="panel-body">
            <div class="col-xs-6">
              <h3>{{ $route.params.id }}</h3>
            </div>
          </div>
          <div class="panel-body">
            <div class="col-xs-6">
              <form role="form">
                <div class="form-group">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Estado</label>
                      <select class="form-control" v-model="assetExchangeActivityData.status">
                        <option value="PENDI">Pendiente</option>
                        <option value="REJEC">Rechazado</option>
                        <option value="APPRO">Aprobado</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Acciones</label>
                      <p class="p-t-8">
                        <i
                          class="mdi mdi-content-save mdi-18px"
                          @click="actionAssetExchangeManagerUpdateStatus(assetExchangeActivityData)"
                        ></i>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="panel-body">
            <div class="col-md-8">
              <div class="panel-body">
                <div class="col-xs-12">
                  <div class="form-group">
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Título de canje</label>
                        <br />
                        <span>{{ assetExchangeActivityData.asset_exchange.name }}</span>
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Id de usuario</label>
                        <br />
                        <span>{{ assetExchangeActivityData.user.code_user }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="col-xs-12">
                  <div class="form-group">
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Nivel del canje</label>
                        <br />
                        <span>{{ assetExchangeActivityData.asset_exchange.level.name }}</span>
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Nombres y Apellidos</label>
                        <br />
                        <span>{{ assetExchangeActivityData.user.first_name }} {{ assetExchangeActivityData.user.last_name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="col-xs-12">
                  <div class="form-group">
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Puntos</label>
                        <br />
                        <span>{{ assetExchangeActivityData.asset_exchange.point_exchange }}</span>
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Nivel usuario</label>
                        <br />
                        <span>{{ assetExchangeActivityData.user.level.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="col-xs-12">
                  <div class="form-group">
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Stock</label>
                        <br />
                        <span>{{ assetExchangeActivityData.asset_exchange.stock }}</span>
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Email</label>
                        <br />
                        <span>{{ assetExchangeActivityData.user.email }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="col-xs-12">
                  <div class="form-group">
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Fecha de solicitud</label>
                        <br />
                        <span>{{ assetExchangeActivityData.created }}</span>
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Teléfono de contacto</label>
                        <br />
                        <span>{{ assetExchangeActivityData.user.phone_movil }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ref } from "vue";
import store from "@/store";
import router from "@/router";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import Title from "@/components/Title";
import API from "@/api";

export default {
  name: "AssetExchangeManager",
  components: {
    Breadcrumbs,
    Title,
    Loading
  },
  setup() {
    var isLoading = ref(false);
    const fullPage = true;
    let assetExchangeDetailId = router.currentRoute._value.params.id;
    const dataViewAssetExchangeManagerDetail = ref([
      {
        name: "Gestor de canjes",
        nameRouter: "AssetExchangeManager",
        link: true
      },
      {
        params: assetExchangeDetailId,
        link: false
      }
    ]);
    const dataViewTitle = ref({
      name: "Gestor de canjes"
    });
    const assetExchangeActivityData = ref({
      asset_exchange: { level: {} },
      user: { level: {} },
      shop: {}
    });

    const getAssetExchangeActivity = id => {
      API.get(
        "/api/asset-exchange-activity/" + router.currentRoute._value.params.id
      ).then(response => {
        assetExchangeActivityData.value = response.data;
      });
    };

    const actionAssetExchangeManagerUpdateStatus = c => {
      isLoading.value = true;
      store
        .dispatch("actionAssetExchangeManagerUpdateStatus", c)
        .then(response => {
          console.log("OK!");
          isLoading.value = false;
        })
        .catch(error => {
          console.log(error.response.data);
        });
    };

    return {
      isLoading,
      fullPage,
      dataViewAssetExchangeManagerDetail,
      dataViewTitle,
      assetExchangeActivityData,
      getAssetExchangeActivity,
      actionAssetExchangeManagerUpdateStatus
    };
  },
  created() {
    this.getAssetExchangeActivity();
  }
};
</script>