<template>
    <div class="left side-menu">
        <div class="sidebar-inner slimscrollleft" style="background-color: #003F72">
            <!-- <div class="user-details">
                <div class="text-center">
                    <img src="assets/images/users/avatar-1.jpg" alt="" class="img-circle">
                </div>
                <div class="user-info">
                    <div class="dropdown">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">Kenny Rigdon</a>
                        <ul class="dropdown-menu">
                            <li><a href="javascript:void(0)"> Profile</a></li>
                            <li><a href="javascript:void(0)"> Settings</a></li>
                            <li><a href="javascript:void(0)"> Lock screen</a></li>
                            <li class="divider"></li>
                            <li><a href="javascript:void(0)"> Logout</a></li>
                        </ul>
                    </div>

                    <p class="text-muted m-0"><i class="fa fa-dot-circle-o text-success"></i> Online</p>
                </div>
            </div> -->
            <!--- Divider -->
            <div id="sidebar-menu">
                <ul>
                    <li>
                        <!-- <a href="index.html" class="waves-effect"><i class="ti-home"></i><span> Dashboard </span></a> -->
                        <router-link :to="{ name: 'Home' }" class="waves-effect"><i class="ti-settings"></i><span>Ajustes globales</span></router-link>                        
                    </li>

                    <li>
                        
                        <router-link :to="{ name: 'Analytic' }" class="waves-effect"><i class="ion-stats-bars"></i><span>Analítica</span></router-link>
                    </li>

                    <li class="has_sub">
                        <a href="javascript:void(0);" class="waves-effect">
                            <i class="ti-agenda"></i> <span> Club Barrington </span> <span class="pull-right"><i class="mdi mdi-plus"></i></span>
                        </a>
                        <ul class="list-unstyled">
                            <li><router-link class="waves-effect" :to="{ name: 'ItemLineManager' }">Linea de artículos</router-link></li>
                            <li><router-link class="waves-effect" :to="{ name: 'LevelPoint' }">Niveles y puntos</router-link></li>
                            <li><router-link class="waves-effect" :to="{ name: 'PointManager' }">Gestor de puntos</router-link></li>
                            <li><router-link class="waves-effect" :to="{ name: 'AssetExchangeManager' }">Gestor de canjes</router-link></li>                            
                        </ul>
                    </li>
                    
                    <li>
                        <router-link :to="{ name: 'User' }" class="waves-effect" ><i class="ti-user"></i><span> Usuarios </span></router-link>
                        
                    </li>

                    <li>
                        <!-- <router-link :to="{ name: 'Shop' }" class="waves-effect" ><i class="fa-shopping-basket"></i><span> Tiendas </span></router-link> -->
                        <router-link :to="{ name: 'Shop' }" class="waves-effect" ><img src="/assets/icons/ico_tiendas.svg" class="store-icon"><span> Tiendas </span></router-link>
                        
                    </li>

                    <li>
                        <router-link :to="{ name: 'AssetExchange' }" class="waves-effect" ><i class="ti-star"></i><span> Canjes </span></router-link>                        
                    </li>

                    
                </ul>
            </div>
            <div class="clearfix"></div>
        </div> <!-- end sidebarinner -->
    </div>
</template>

