<template>
    <div class="container">
        <loading :active="isLoading"
                 :can-cancel="false"
                 :on-cancel="onCancel"                 
                 :is-full-page="fullPage"/>             
        
        <Breadcrumbs :data=dataViewBreadcrumbs />    
        <Title :data=dataViewTitle />

        <div class="row">
            <div class="col-md-10">
                <div class="panel panel-primary">
                    
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-xs-12">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Descripción</th>
                                            <th>Desde</th>
                                            <th>Hasta</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>VENTAS (Lista de puntos registrados)</td>
                                            <td><Datepicker class="form-control" v-model="dataAnalytic.saleAnalyticDateStart" placeholder="Desde" /></td>
                                            <td><Datepicker class="form-control" v-model="dataAnalytic.saleAnalyticDateEnd" placeholder="Hasta" /></td>
                                            <td>
                                                <a href="#" @click="downloadPointActivity()">
                                                <i class="ti-download"></i> Exportar XLS
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>USUARIOS</td>
                                            <td><Datepicker class="form-control" v-model="dataAnalytic.userAnalyticDateStart" placeholder="Desde" /></td>
                                            <td><Datepicker class="form-control" v-model="dataAnalytic.userAnalyticDateEnd" placeholder="Hasta" /></td>
                                            <td>
                                                <a href="#" @click="downloadUser()">
                                                <i class="ti-download"></i> Exportar XLS
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>TIENDAS</td>
                                            <td><Datepicker class="form-control" v-model="dataAnalytic.shopAnalyticDateStart" placeholder="Desde" /></td>
                                            <td><Datepicker class="form-control" v-model="dataAnalytic.shopAnalyticDateEnd" placeholder="Hasta" /></td>
                                            <td>
                                                <a href="#" @click="downloadShop()">
                                                <i class="ti-download"></i> Exportar XLS
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>CANJES</td>
                                            <td><Datepicker class="form-control" v-model="dataAnalytic.assetExchangeActivityAnalyticDateStart" placeholder="Desde" /></td>
                                            <td><Datepicker class="form-control" v-model="dataAnalytic.assetExchangeActivityAnalyticDateEnd" placeholder="Hasta" /></td>
                                            <td>
                                                <a href="#" @click="downloadAssetExchangeActivity()">
                                                <i class="ti-download"></i> Exportar XLS
                                                </a>
                                            </td>                                            
                                        </tr>
                                        <tr>
                                            <td>STOCK CANJES</td>
                                            <td colspan="2">&nbsp;</td>
                                            <!-- <td><input type="text" class="form-control" v-model="dataAnalytic.assetExchangeAnalyticDateStart" placeholder="Fecha inicio"></td>
                                            <td><input type="text" class="form-control" v-model="dataAnalytic.assetExchangeAnalyticDateEnd" placeholder="Fecha fin"></td> -->
                                            <td>
                                                <a href="#" @click="downloadAssetExchange()">
                                                <i class="ti-download"></i> Exportar XLS
                                                </a>
                                            </td>                                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Datepicker from 'vue3-datepicker'
import { mapActions, mapState } from "vuex";
import Breadcrumbs from '@/components/Breadcrumbs'
import Title from '@/components/Title'
import { ref } from "vue";
import API from "@/api.js";

export default {
    name: "Analytic",
    components: {
        Breadcrumbs,
        Title,
        Datepicker,
        Loading,
    },
    setup() {
        var isLoading = ref(false)
        const fullPage = true

        const dataViewBreadcrumbs = ref({
            name: 'Analítica'
        })
        const dataViewTitle = ref({
            name: dataViewBreadcrumbs.value.name
        })

        const dataAnalytic = ref({
            shopAnalyticDateStart: undefined,
            shopAnalyticDateEnd: undefined,
            userAnalyticDateStart: undefined,
            userAnalyticDateEnd: undefined,
            saleAnalyticDateStart: undefined,
            saleAnalyticDateEnd: undefined,
            assetExchangeActivityAnalyticDateStart: undefined,
            assetExchangeActivityAnalyticDateEnd: undefined,
            assetExchangeAnalyticDateStart: undefined,
            assetExchangeAnalyticDateEnd: undefined,
        })
        
        const onlyDate = (d) => {            
            //obtiene solo la parte de fecha, quitando las horas y minutos
            return d.toISOString().split('T')[0]            
        }

        const downloadAssetExchangeActivity = () => {
            isLoading.value = true
            let dateStart = onlyDate(dataAnalytic.value.assetExchangeActivityAnalyticDateStart)
            let dateEnd = onlyDate(dataAnalytic.value.assetExchangeActivityAnalyticDateEnd)

            return API.get("/api/asset-exchange-activity-download?" + "date_start=" + dateStart + "&date_end=" + dateEnd, {responseType: 'blob'})
                .then(response => {                    
                    isLoading.value = false
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "canjes.xlsx")
                    document.body.appendChild(link)
                    link.click()
                })
        }

        const downloadPointActivity = () => {
            isLoading.value = true
            let dateStart = onlyDate(dataAnalytic.value.saleAnalyticDateStart)
            let dateEnd = onlyDate(dataAnalytic.value.saleAnalyticDateEnd)
            
            return API.get("/api/point-activity-download?" + "date_start=" + dateStart + "&date_end=" + dateEnd, {responseType: 'blob'})
                .then(response => {                    
                    isLoading.value = false
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "ventas.xlsx")
                    document.body.appendChild(link)
                    link.click()
                })
        }
        
        const downloadUser = () => {
            isLoading.value = true
            let dateStart = onlyDate(dataAnalytic.value.userAnalyticDateStart)
            let dateEnd = onlyDate(dataAnalytic.value.userAnalyticDateEnd)
            return API.get("/api/user-download?" + "date_start=" + dateStart + "&date_end=" + dateEnd, {responseType: 'blob'})
                .then(response => {                    
                    isLoading.value = false
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "usuarios.xlsx")
                    document.body.appendChild(link)
                    link.click()
                })
        }

        const downloadShop = () => {
            isLoading.value = true
            let dateStart = onlyDate(dataAnalytic.value.shopAnalyticDateStart)
            let dateEnd = onlyDate(dataAnalytic.value.shopAnalyticDateEnd)
            
            return API.get("/api/shop-download?" + "date_start=" + dateStart + "&date_end=" + dateEnd, {responseType: 'blob'})
                .then(response => {                    
                    isLoading.value = false
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "tiendas.xlsx")
                    document.body.appendChild(link)
                    link.click()
                })
        }

        const downloadAssetExchange = () => {            
            isLoading.value = true
            return API.get("/api/asset-exchange-download", {responseType: 'blob'})
                .then(response => {                    
                    isLoading.value = false
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "stock_canjes .xlsx")
                    document.body.appendChild(link)
                    link.click()
                })
        }

        return { 
            isLoading,
            fullPage,
            dataViewBreadcrumbs, 
            dataViewTitle, 
            dataAnalytic, 
            downloadAssetExchange, 
            downloadAssetExchangeActivity,
            downloadPointActivity,
            downloadUser,
            downloadShop,
            ...mapActions(['actionGetNotify']) 
        }

    },
    mounted() {
        this.actionGetNotify()
    }
    

}
</script>