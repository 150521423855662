<template>
    <div class="form-group">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="exampleInputEmail1">Puntos aprobados</label>
                <br>
                <span>{{ data.point_register }}</span>
            </div>
        </div>        
    </div>
</template>

<script>
export default {
    props: {
        data: Object
    }
}
</script>