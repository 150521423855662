<template>
  <div class="container">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <Breadcrumbs :data="dataViewShopDetail" />
    <Title :data="dataViewTitle" />
    <div class="row">
      <div class="col-lg-10">
        <div class="page-content">
          <div class="tabbed">
            <input type="radio" id="tab1" name="css-tabs" checked />
            <input type="radio" id="tab2" name="css-tabs" />
            <ul class="tabs">
              <li class="tab">
                <label for="tab1">Información personal</label>
              </li>
              <li class="tab">
                <label for="tab2">Registro histórico de puntos</label>
              </li>
            </ul>
            <div class="tab-content user-detail">
              <div class="panel-body">
                <div class="col-md-10">
                  <div class="row">
                    <ShopResumePoint :data="dataShop" />
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="col-md-2">
                  <div class="form-group">
                    <br />
                    <!-- <img src="https://dummyimage.com/150x150/5974ba/ffffff.png&text=Sin+imagen" alt="" class="img-circle"> -->
                    <img
                      :src="dataShop.photo ? `${baseURL}${dataShop.photo}` : 'https://dummyimage.com/150x150/5974ba/ffffff.png'"
                      class="image-profile"
                    />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Estado</label>
                          <select class="form-control" v-model="dataShop.status">
                            <option value="PENDI">Pendiente</option>
                            <option value="REJEC">Rechazado</option>
                            <option value="SUSPE">Suspendido</option>
                            <option value="APPRO">Aprobado</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Acciones</label>
                          <p class="p-t-8">
                            <!-- <i class="mdi mdi-content-save mdi-18px" @click="actionShopUpdateStatus(dataShop)"></i> -->
                            <i
                              class="mdi mdi-content-save mdi-18px"
                              @click="actionShopUpdateStatus(dataShop)"
                            ></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Razón social</label>
                        <span>{{ dataShop.business_name }}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Nombre comercial</label>
                        <span>{{ dataShop.tradename }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">RUC:</label>
                        <span>{{ dataShop.ruc }}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Representante legal o contacto de tienda:</label>

                        <span>{{ dataShop.legal_representative }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Teléfono de contacto:</label>
                        <span>{{ dataShop.phone }}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Movil / Whastapp</label>
                        <span>{{ dataShop.phone_movil }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Dirección:</label>
                        <span>{{ dataShop.address }}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Email:</label>
                        <span>{{ dataShop.email }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Ciudad / Departamento / País:</label>
                        <span v-if="dataShop.ubigeo">{{ dataShop.ubigeo.name }}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Letrero Barrington:</label>
                        <span>{{ dataShop.sign_barrington }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Fecha de registro:</label>
                        <span>{{ dataShop.created }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content user-detail">
              <div class="panel-body">
                <div class="col-md-10">
                  <div class="row">
                    <ShopResumePoint :data="dataShop" />
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="col-md-10">
                  <div class="row">
                    <ShopHistoricalPoint :data="dataShopHistoricalPoint" />
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ref } from "vue";
import router from "@/router";
import store from "@/store";
import API from "@/api";
import { mapActions } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import Title from "@/components/Title";
import ShopResumePoint from "@/components/shop/ShopResumePoint";
import ShopHistoricalPoint from "@/components/shop/ShopHistoricalPoint";

export default {
  name: "UserDetail",
  components: {
    Breadcrumbs,
    Title,
    ShopResumePoint,
    ShopHistoricalPoint,
    Loading
  },
  setup() {
    var isLoading = ref(false);
    const fullPage = true;

    const baseURL = API.defaults.baseURL;

    const dataCompResumePoint = ref({});
    const dataShopHistoricalPoint = ref([]);
    const dataShop = ref({});

    const shopId = router.currentRoute._value.params.id;
    const dataViewShopDetail = ref([
        {
            name: "Tiendas",
            nameRouter: "Shop",
            link: true,
        },
        {            
            params: shopId,
            link: false,
        }
    ]);

    const dataViewTitle = ref({
      name: "Tienda"
    });

    const getShopHistoricalPoint = () => {
      API.get("/api/point-activity?shop=" + shopId).then(response => {
        dataShopHistoricalPoint.value = response.data;
      });
    };

    const getShop = () => {
      API.get("/api/shop/" + shopId).then(response => {
        dataShop.value = response.data;
      });
    };

    const actionShopUpdateStatus = c => {
      isLoading.value = true;
      store
        .dispatch("actionShopUpdateStatus", c)
        .then(response => {
          console.log("OK!");
          isLoading.value = false;
        })
        .catch(error => {
          console.log(error.response.data);
        });
    };

    return {
      isLoading,
      fullPage,
      dataViewShopDetail,
      dataViewTitle,
      dataCompResumePoint,
      dataShopHistoricalPoint,
      getShopHistoricalPoint,
      dataShop,
      getShop,
      baseURL,
      //...mapActions(['actionShopUpdateStatus'])
      actionShopUpdateStatus
    };
  },
  created() {
    this.getShopHistoricalPoint();
    this.getShop();
  }
};
</script>



<style scoped>
.page-content {
  /* max-width: 700px;  */
  /* margin: 32px auto;  */
  margin-left: 32px auto;
  /* padding: 32px;  */
  padding-left: 32px;
  background: #fff;
}
a {
  color: #21d4fd;
  transition: all 0.3s;
}
a:hover {
  color: #b721ff;
}

@media (min-width: 400px) and (max-width: 630px) {
  .tabbed {
    overflow-x: scroll !important;
  }
}

.tabbed {
  overflow-x: hidden; /* so we could easily hide the radio inputs */
  /* margin: 32px 0; */
  margin-left: 32px auto;
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
}

.tabbed [type="radio"] {
  /* hiding the inputs */
  display: none;
}

.tabs {
  display: flex;
  align-items: stretch;
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #ccc;
}
.tab > label {
  display: block;
  margin-bottom: -1px;
  padding: 12px 15px;
  border: 1px solid #ccc;
  background: #eee;
  color: #666;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s;
}
.tab:hover label {
  border-top-color: #333;
  color: #333;
}

.tab-content {
  display: none;
  color: #777;
}

/* As we cannot replace the numbers with variables or calls to element properties, the number of this selector parts is our tab count limit */
.tabbed [type="radio"]:nth-of-type(1):checked ~ .tabs .tab:nth-of-type(1) label,
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tabs .tab:nth-of-type(2) label,
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tabs .tab:nth-of-type(3) label,
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tabs .tab:nth-of-type(4) label,
.tabbed
  [type="radio"]:nth-of-type(5):checked
  ~ .tabs
  .tab:nth-of-type(5)
  label {
  border-bottom-color: #fff;
  /* border-top-color: #B721FF; */
  background: #fff;
  color: #222;
}

.tabbed [type="radio"]:nth-of-type(1):checked ~ .tab-content:nth-of-type(1),
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tab-content:nth-of-type(2),
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tab-content:nth-of-type(3),
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tab-content:nth-of-type(4) {
  display: block;
}
</style>