<template>
  
    <div class="accountbg"></div>
        <div class="wrapper-page">
            <div class="panel panel-color panel-primary panel-pages">

                <div class="panel-body">
                    <h3 class="text-center m-t-0 m-b-30">
                        <span class=""><img :src="`${prefixStatic}assets/images/logo.png`" alt="logo" height="32" style="height:auto;"></span>
                    </h3>
                    <h4 class="text-muted text-center m-t-0"><b>Acceder al Administrador</b>
                    
                    </h4>

                    <form class="form-horizontal m-t-20" @submit.prevent>

                        <div class="form-group">
                            <div class="col-xs-12">
                                <input class="form-control" type="email" required="" placeholder="Email" v-model="form.email">
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-xs-12">
                                <input class="form-control" type="password" required="" placeholder="Contraseña" v-model="form.password">
                            </div>
                        </div>

                        <!-- <div class="form-group">
                            <div class="col-xs-12">
                                <div class="checkbox checkbox-primary">
                                    <input id="checkbox-signup" type="checkbox">
                                    <label for="checkbox-signup">
                                        Remember me
                                    </label>
                                </div>
                            </div>
                        </div> -->
                        <p class="form__alert">
                            {{ store.state.dataMessageErrorLogin.detail }}
                        </p>
                        <div class="form-group text-center m-t-20">
                            <div class="col-xs-12">
                                <button class="btn btn-success waves-effect waves-light" type="submit" @click="login(form)">Inciar Sesión</button>
                            </div>
                        </div>
                        <div class="form-group m-t-30 m-b-0">
                            <div class="col-sm-7">
                                <!-- <a href="#" class="text-muted"><i class="fa fa-lock m-r-5"></i>Recuperar contraseña</a> -->
                            </div>
                        </div>

                        <!-- <div class="form-group m-t-30 m-b-0">
                            <div class="col-sm-7">
                                <a href="pages-recoverpw.html" class="text-muted"><i class="fa fa-lock m-r-5"></i> Forgot your password?</a>
                            </div>
                            <div class="col-sm-5 text-right">
                                <a href="pages-register.html" class="text-muted">Create an account</a>
                            </div>
                        </div> -->
                    </form>
                </div>

            </div>
        </div>


  
</template>

<script>
import { ref } from "vue";
import API from "@/api";
import store from '@/store';
import { mapActions, mapState } from "vuex";

export default {
    
    setup(){
        const prefixStatic = API.defaults.prefixStatic || ''
        const form = ref({ 
            email: "",
            password: "",            
        });        

        const messagePost = ref({})
        const messageError = ref({errors:{}})          
        
        return {form, prefixStatic, messagePost, messageError, ...mapActions(['login']),  store}
    },
    mounted() {
        //console.log(process.env, 'ENVVV')
    }
}
</script>