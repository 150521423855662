<template>
    <div>
        <form role="form" @submit.prevent>
            <div class="form-group">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Código</label>
                        <input type="text" class="form-control" v-model="formItem.code" />
                    </div>
                    <p
                        class="form__alert"
                        v-if="formItemError.errors.code"
                    >{{ formItemError.errors.code[0] }}</p>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Nombre</label>
                        <input type="text" class="form-control" v-model="formItem.name" />
                    </div>
                    <p
                        class="form__alert"
                        v-if="formItemError.errors.bonus_point"
                    >{{ formItemError.errors.bonus_point[0] }}</p>
                </div>
                <div class="col-sm-1">
                    <div class="form-group">
                        <label for="exampleInputEmail1">&nbsp;</label>
                        <button
                            type="submit"
                            class="btn btn-success waves-effect waves-light m-l-10"
                            @click="submitFormItem()"
                        >Crear</button>
                    </div>
                </div>
            </div>
        </form>
        <div class="col-xs-12">
            <div class="table-responsive">
                
                <table class="table">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nombre</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v, k) in dataItem" :key="k">
                            <template v-if="!v.editMode">
                                <td>{{ v.code }}</td>
                                <td>{{ v.name }}</td>
                            </template>
                            <template v-else>
                                <td>
                                    <input type="text" v-model="v.code" class="form-control" />
                                </td>
                                <td>
                                    <input type="text" v-model="v.name" class="form-control" />
                                </td>
                            </template>

                            <td>
                                <i
                                    v-if="!v.editMode"
                                    @click="editItem(v)"
                                    class="mdi mdi-pencil mdi-18px"
                                ></i>&nbsp;
                                <i
                                    v-else
                                    @click="updateItem(v)"
                                    class="mdi mdi-content-save mdi-18px"
                                ></i>&nbsp;
                                <i
                                    @click="deleteItem(v)"
                                    class="mdi mdi-close-circle-outline mdi-18px"
                                ></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import Swal from "sweetalert2";
import store from "@/store";
export default {
    name: "Item",
    setup() {
        const formItem = ref({
            name: null,
            code: ""
        });
        const formItemError = ref({ errors: {} });
        const dataItem = ref([]);
        const editMode = ref(false);

        const editItem = obj => {
            obj["editMode"] = true;
        };

        const submitFormItem = () => {
            store
                .dispatch("postItem", formItem.value)
                .then(response => {
                    //debugger;
                    formItem.value = {};
                    getItem();
                    Swal.fire({
                        icon: "success",
                        title: "Se guardaron los cambios",
                        text: "Artículo"
                    });
                })
                .catch(error => {
                    formItemError.value = error.response.data;
                });
        };
        const getItem = () => {
            store.dispatch("getItem").then(response => {
                dataItem.value = response.data;
            });
        };
        const updateItem = obj => {        
            store
                .dispatch("putItem", obj)
                .then(response => {
                    obj["editMode"] = false;
                    console.log("updated!");
                })
                .catch(error => {
                    console.log(error.response.data, "xxxxxxxxx");
                });
        };

        const deleteItem = (obj) => {
            Swal.fire({
                title: '¿Desea eliminar este artículo?',
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: "SÍ",
                
                }).then(async (result) => {
                    if (result.isConfirmed) {                        
                        await store.dispatch("deleteItem", obj)                        
                        getItem()
                    }
                })
        }

        return {
            submitFormItem,
            deleteItem,
            updateItem,
            dataItem,
            formItem,
            formItemError,
            editMode,
            editItem,
            getItem
        };
    },
    mounted() {
        this.getItem();
    }
};
</script>