import { createStore } from 'vuex'
import Swal from 'sweetalert2'
import API from "@/api"
import router from "@/router"
import store from '@/store';
import { ErrorCodes, ref } from 'vue'

export default createStore({
    state: {

        showSideBar: false,
        dataToken: {},
        dataMessageErrorLogin: {},
        notify: {},

    },
    mutations: {
        setState(state, payload) {
            //debugger

            state.showSideBar = payload.showSideBar

            state.dataToken.token = payload.access || payload.token
            state.dataToken.typeUser = payload.type_user
            state.dataToken.name = payload.name
            state.dataToken.address = payload.address
            state.dataToken.department = payload.department
            state.dataToken.country = payload.country
            state.dataToken.codeUser = payload.code_user
            state.dataToken.typeUserLabel = payload.type_user_label
            state.dataToken.id = payload.id
            state.dataToken.shop = payload.shop
            state.dataToken.total_point = payload.total_point
            state.dataToken.level = payload.level
            state.dataToken.level_label = payload.level_label
            state.dataToken.point_register = payload.point_register
        },
        setStateMessageErrorLogin(state, payload) {
            //debugger
            state.dataMessageErrorLogin = payload
        },
        setStateNotify(state, payload) {
            //debugger
            state.notify = payload
        }
    },
    actions: {

        login({ commit }, user) {
            //debugger
            const res = API.post("/api/token/", user)
                .then(
                    response => {
                        if (response.data.type_user == 'ADMIN') {

                            commit('setState', response.data)
                            localStorage.setItem('token', response.data.access)
                            localStorage.setItem('name', response.data.name)
                            localStorage.setItem('type_user', response.data.type_user)
                            localStorage.setItem('address', response.data.address)
                            localStorage.setItem('department', response.data.department)
                            localStorage.setItem('country', response.data.country)
                            localStorage.setItem('code_user', response.data.code_user)
                            localStorage.setItem('type_user_label', response.data.type_user_label)
                            localStorage.setItem('id', response.data.id)
                            localStorage.setItem('shop', response.data.shop)
                            localStorage.setItem('total_point', response.data.total_point)
                            localStorage.setItem('level', response.data.level)
                            localStorage.setItem('level_label', response.data.level_label)
                            localStorage.setItem('point_register', response.data.point_register)
                            return router.push({ name: 'Home' })
                        }

                    }
                ).catch(error => {
                    console.log(error.response)
                    commit('setStateMessageErrorLogin', error.response.data)
                })

        },

        actionSetState({ commit }, data) {
            commit('setState', data)
        },

        actionUserUpdateStatus({ commit }, user) {
            //debugger
            let data = {
                status: user.status,
                bonus_point_image_project: user.bonus_point_image_project
            }
            return API.put("/api/user/" + user.id, data)
                .then(response => {
                    console.log('update!')
                    Swal.fire({
                        icon: 'success',
                        title: 'Se guardaron los cambios',
                        text: 'Gestor de puntos',
                    })

                })
        },
        actionAssetExchangeManagerUpdateStatus({ commit }, data) {
            let payload = {
                status: data.status
            }
            return API.put("/api/asset-exchange-activity/" + data.id, payload)
                .then(response => {
                    console.log('update!')
                    Swal.fire({
                        icon: 'success',
                        title: 'Se guardaron los cambios',
                        text: 'Gestor de puntos',
                    })

                })
                .catch(error => {
                    console.log(error.response.data)

                    Swal.fire({
                        icon: 'warning',
                        title: error.response.data.errors.non_field_errors,
                        text: 'Gestor de canjes',
                    })
                })
        },

        actionShopUpdateStatus({ commit }, shop) {
            let data = {
                status: shop.status
            }
            return API.put("/api/shop/" + shop.id, data)
                .then(response => {
                    console.log('update!')
                    Swal.fire({
                        icon: 'success',
                        title: 'Se guardaron los cambios',
                        text: 'Gestor de tiendas',
                    })

                })
        },

        putNotify({ commit }, payload) {
            return API.put("/api/notify/" + payload.param, {}, {
                headers: {
                    Authorization: "Bearer " + this.state.dataToken.token
                }
            })
        },

        postSms({ commit }, payload) {
            return API.post("/api/send-sms", payload, {
                headers: {
                    Authorization: "Bearer " + this.state.dataToken.token
                }
            })
        },

        actionGetNotify({ commit }, payload) {
            /*se llama en el componente Topbar.vue, es decir en todas las vistas*/
            //debugger            
            API.get("/api/notify", {
                headers: {
                    Authorization: "Bearer " + store.state.dataToken.token
                }
            })
                .then(response => {
                    commit("setStateNotify", response.data)
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.dispatch("closeSession")
                    } else {
                        console.log(error.response.data)
                    }
                })
        },

        getQueryUserAction({ commit }, payload) {
            return API.get("/api/user?" + payload)
        },

        getQueryShopAction({ commit }, payload) {
            return API.get("/api/shop?" + payload)
        },

        getQueryPointActivity({ commit }, payload) {
            return API.get("/api/point-activity?" + payload)
        },

        getPointActivity({ commit }, payload) {
            return API.get("/api/point-activity/" + payload.id)
        },
        getQueryAssetExchange({ commit }, payload) {
            return API.get("/api/asset-exchange-activity?" + payload)
        },
        getPointActivityDetailFilterPointActivity({ commit }, payload) {
            return API.get("/api/point-activity-detail/point-activity/" + payload.pointActivityId)
        },
        getColor({ commit }, payload) {
            let params = new URLSearchParams(payload).toString();
            return API.get(`/api/color?${params}`)
        },
        postColor({ commit }, payload) {
            return API.post("/api/color", payload)
        },
        putColor({ commit }, payload) {            
            return API.put(`/api/color/${payload.id}`, payload)
        },
        deleteColor({ commit }, payload) {    
                    
            return API.delete(`/api/color/${payload.id}`)
        },
        getItem({ commit }, payload) {
            let params = new URLSearchParams(payload).toString();
            return API.get(`/api/item?${params}`)
        },
        postItem({ commit }, payload) {
            return API.post("/api/item", payload)
        },
        putItem({ commit }, payload) {            
            return API.put(`/api/item/${payload.id}`, payload)
        },
        deleteItem({ commit }, payload) {                        
            return API.delete(`/api/item/${payload.id}`)
        },
        getItemColor({ commit }, payload) {
            let params = new URLSearchParams(payload).toString();
            return API.get(`/api/item-color?${params}`)
        },
        postItemColor({ commit }, payload) {
            return API.post("/api/item-color", payload)
        },
        putItemColor({ commit }, payload) {            
            return API.put(`/api/item-color/${payload.id}`, payload)
        },
        deleteItemColor({ commit }, payload) {                        
            return API.delete(`/api/item-color/${payload.id}`)
        },
        
        getLineItem({ commit }, payload) {
            let params = new URLSearchParams(payload).toString();
            return API.get(`/api/product-for-exchange?${params}`)
        },
        getLineItemColor({ commit }, payload) {
            return API.get("/api/product-for-exchange-item-color")
        },
        postLineItemColor({ commit }, payload) {
            return API.post("/api/product-for-exchange-item-color", payload)
        },
        putLineItemColor({ commit }, payload) {            
            return API.put(`/api/product-for-exchange-item-color/${payload.id}`, payload)
        },
        deleteLineItemColor({ commit }, payload) {                        
            return API.delete(`/api/product-for-exchange-item-color/${payload.id}`)
        },
            

        readToken({ commit }) {
            if (localStorage.getItem('token')) {
                commit('setState', localStorage)
            } else {
                commit('setState', {})
            }
        },
        closeSession({ commit }) {
            localStorage.removeItem('token')
            return router.push({ name: 'Login' })
            //window.location.replace(API.defaults.urlPublic)
        }
    },
    getters: {
        config: state => state.dataToken.token
    },
    modules: {
    }
})
