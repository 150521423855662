<template>
    <ol class="breadcrumb">
        <li>
            <router-link :to="{ name: 'Home' }">Home</router-link>
        </li>
        <li v-for="(v, k) in data" :key="k">
            <template v-if="v.link">
                <template v-if="v.params">
                    <router-link :to="{ name: v.nameRouter, params: { id: v.params } }">{{ v.name }}</router-link>
                </template>
                <template v-else>
                    <router-link :to="{ name: v.nameRouter }">{{ v.name }}</router-link>
                </template>
            </template>
            <template v-else>
                <template v-if="v.params">{{ v.params }}</template>
                <template v-else>{{ v.name }}</template>
            </template>
        </li>
        <!-- <li><a href="#">Xadmino</a></li>
        <li class="active">Dashboard</li>-->
    </ol>
</template>

<script>
export default {
    name: "Breadcrumbs",
    props: {
        data: Object
    }
};
</script>