<template>
    <div class="form-group">
        <div class="col-lg-10">
            <div class="page-content">                                        
                <div class="panel-body">               
                    <div class="row">
                        <div class="col-md-12">                                                                          
                            <div class="form-group col-md-6">                                        
                                <label for="">Logotipo principal de encabezado</label>
                                <input type="file" accept="image/*" class="form__field" @change="uploadImage($event)">
                            </div> 
                            <div class="form-group col-md-6 backImage">
                                <img :src="location + formHeaderMain.logotype" class="imgHeader" />
                            </div> 
                            <br />
                            <div class="form-group col-md-12">
                                <label for="">
                                    <br/>
                                    Edita opciones de sub-menus de "Sobre Club Barrington". (6 posiciones fijas, nuevos items removibles)
                                </label>
                            </div>  
                            <br />
                            <div v-for="(item, key) in formHeaderDetailItems" :key="key">
                                <div class="form-group col-md-2">                                        
                                    <label for="">Orden</label>
                                    <input type="number" class="form-control" v-model="item.order"  />
                                </div>   
                                <div class="form-group col-md-3">                                        
                                    <label for="">Nombre</label>
                                    <input type="text" class="form-control" v-model="item.name"  />
                                </div>
                                <div class="form-group col-md-3" v-if="item.permanent">    
                                    <label for="">Path</label>
                                    <input type="text" class="form-control" v-model="item.path" readonly  />
                                </div>                                
                                <div class="form-group col-md-2" v-if="item.permanent">
                                    <label for="">Off/On</label>
                                    <select class="form-control" v-model="item.visible">
                                        <option :value=null>Seleccione</option>
                                        <option :value="target.code" v-for="(target) in dataParam.show" :key="target.code">{{ target.name }}</option>
                                    </select>
                                </div> 
                                <div class="form-group col-md-12" v-if="item.permanent">                                        
                                </div>                          
                                <div class="form-group col-md-2" v-if="!item.permanent">        
                                    <label for="">Url</label>
                                    <input type="text" class="form-control" v-model="item.url"  />
                                </div>
                                <div class="form-group col-md-2" v-if="!item.permanent">        
                                    <label for="">Target</label>
                                    <select class="form-control" v-model="item.target">
                                        <option :value=null>Seleccione</option>
                                        <option :value="target.code" v-for="(target) in dataParam.target" :key="target.code">{{ target.name }}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-2" v-if="!item.permanent">
                                    <label for="">Off/On</label>
                                    <select class="form-control" v-model="item.visible">
                                        <option :value=null>Seleccione un target</option>
                                        <option :value="target.code" v-for="(target) in dataParam.show" :key="target.code">{{ target.name }}</option>
                                    </select>
                                </div>   
                                <div class="form-group col-md-1" v-if="!item.permanent">        
                                    <label for="">&nbsp;</label>
                                    <button type="button" class="btn btn-success waves-effect waves-light" @click="deleteItem(item)">Borrar</button>                                
                                </div>                                                                    
                            </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
                        </div>
                    </div>     
                </div>
                <div class="panel-body">               
                        
                    <div class="row">
                        <div class="col-md-6">
                            <button type="button" class="btn btn-success waves-effect waves-light" @click="submitFormHeaderNew()">+ Agregar</button>
                                &nbsp;
                            <button type="submit" class="btn btn-success waves-effect waves-light" @click="submitFormHeaderDetail()">Guardar Menu</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from "vue";
import API from "@/api";
import Swal from 'sweetalert2'

function created(){
    this.getHeaderMain();
    this.getHeaderDetail();
}

export default {
    name: 'header',
    created,
    props: {
        data: Object
    },
    setup() {
        
        const formHeaderDetailItems = ref([]);
        const dataFooter = ref([]);
        const formAssetExchangeErrors = ref({errors:{}})
        const location = process.env.VUE_APP_BASE_URL;
        const formHeaderMain = ref({
            errors:[],
            logotype: '',
        });    


        const formHeaderDetailNew = ref({
            errors:[],
            id: 0,
            order: 0,
            name: '',
            path: null,
            url: '',
            target: '_blank',
            permanent: false,
            visible: true,
        });

        const dataParam = ref({
            target : [
                {
                    code: '_blank',
                    name: '_blank',
                }, 
                {
                    code: '_self',
                    name: '_self',
                }, 

            ],
            show: [
                {
                    code: true,
                    name: 'Sí',
                }, 
                {
                    code: false,
                    name: 'No',
                }, 
            ]
        });

        const deleteItem = (item) => {
             if (item.id>0) {
                API.delete('/api/head-menu-navigation-detail/' + item.id).then(response => { this.getHeaderDetail(); })
             }
            
            const index = formHeaderDetailItems.value.indexOf(item);
            formHeaderDetailItems.value.splice(index, 1);

            Swal.fire({
                icon: 'success',
                title: 'Se eliminó el elemento',
                text: 'Gestor de ajustes globales',                
            })
        }

        const uploadImage = (event) => {                           
            formHeaderMain.value.logotype = event.target.files[0];
        }

        const submitFormHeaderNew =() =>{
            const item = {
                errors:[],
                id: 0,
                order: 0,
                name: '',
                url: '',
                target: '_blank',
                permanent: false,
                visible: true,
            }

            formHeaderDetailItems.value.push(item);
        
        }


       const getHeaderMain = () => {      
            API.get('/api/head-menu-navigation')
            .then(response => {      
                formHeaderMain.value = (response.data[0]===undefined?formHeaderMain.value:response.data[0])
            })
        }

       const getHeaderDetail = () => {      
            API.get('/api/head-menu-navigation-detail')
            .then(response => {      
                formHeaderDetailItems.value = response.data
            })
        }        

       const submitFormHeader = () => {      
            const form_data = new FormData();        
            for ( var key in formHeaderMain.value ) {
                form_data.append(key, formHeaderMain.value[key]);
            }           
            API.put('/api/head-menu-navigation', form_data)
            .then(response => {})
            .catch(error => {                                
                formAssetExchangeErrors.value = error.response.data                    
            })
        }

       const submitFormHeaderDetail = () => {    
           
            const form_data_image = new FormData();        
            for ( var key in formHeaderMain.value ) {
                form_data_image.append(key, formHeaderMain.value[key]);
            }           
            API.put('/api/head-menu-navigation', form_data_image)
            .then(response => {})
            .catch(error => {                                
                formAssetExchangeErrors.value = error.response.data                    
            })

            for ( var pk in formHeaderDetailItems.value ) {
                var item = formHeaderDetailItems.value[pk];

                if (item.id===0)
                {
                    item.permanent = false;
                    API.post('/api/head-menu-navigation-detail', item)
                    .then(response => {                          
                    })
                    .catch(error => {                                
                        formAssetExchangeErrors.value = error.response.data                    
                    })
                } else 
                {
                    API.put('/api/head-menu-navigation-detail/' + item.id, item)
                    .then(response => {                          
                    })
                    .catch(error => {                                
                        formAssetExchangeErrors.value = error.response.data                    
                    })                    
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Se guardaron los cambios',
                    text: 'Gestor de ajustes globales',                
                })
                //window.location.reload();
            }        
        }

        return { 
            formHeaderMain,
            formHeaderDetailItems, 
            formHeaderDetailNew, 
            dataParam, 
            dataFooter,  
            getHeaderMain, 
            submitFormHeader, 
            uploadImage, 
            submitFormHeaderNew, 
            submitFormHeaderDetail,
            getHeaderDetail,
            deleteItem,
            location
            }
    }
}
</script>


<style scoped>

.imgHeader{
    max-width: 200px;
    height: 70px;
}

.backImage
{
    background-color:lightgray;
}

</style>