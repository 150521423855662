<template>
    <div class="form-group">
        <div class="col-lg-10">
            <div class="page-content">                                        
                <div class="panel-body">               
                    <div class="row">
                        <div class="col-md-12">                                                                          
                            <div class="form-group col-md-6">                                        
                                <label for="">Logotipo de pie de página</label>
                                <input type="file" accept="image/*" class="form__field" @change="uploadImage($event)">
                                <p class="form__alert" v-if="formAssetFooterError.errors.image">
                                    {{ formAssetFooterError.errors.image[0] }}
                                </p>
                            </div>     
                            <div class="form-group col-md-6 backImage">
                                <img :src="location + formAssetFooter.logo" class="imgHeader" />
                            </div>
                            <br />
                            <br />
                            <div class="form-group col-md-12">
                                <label for="">
                                    <br/>
                                    Edita Información de contacto general
                                </label>
                            </div>                                                           
                            <div class="form-group col-md-12">                                        
                                <label for="">Email</label>
                                <input type="text" class="form-control" v-model="formAssetFooter.email">
                            </div>
                            <div class="form-group col-md-12">
                                <label for="">Teléfono</label>
                                <input type="text" class="form-control" v-model="formAssetFooter.phone">
                            </div>
                            <div class="form-group col-md-12">
                                <label for="">Extensión</label>
                                <input type="text" class="form-control" v-model="formAssetFooter.extension">
                            </div>
                            <div class="form-group col-md-12" v-if="false">
                                <label for="">Dirección</label>
                                <input type="text" class="form-control" v-model="formAssetFooter.address">
                            </div>
                            <div class="form-group col-md-12">
                                <label for="">
                                    <br/>
                                    Edita elementos al footer (íconos redes sociales) 
                                </label>
                            </div>
                            <div class="col-md-3">                                        
                                <label for="">Red Social</label>
                                <input type="text" class="form-control" value="Facebook" readonly />
                            </div>                              
                            <div class="col-md-4">                                        
                                <label for="">Url</label>
                                <input type="text" class="form-control" v-model="formAssetFooter.facebook">
                            </div>                            
                            <div class="col-md-3">                                        
                                <label for="">Target</label>
                                <select class="form-control" v-model="formAssetFooter.facebook_target">
                                        <option :value=null>Activo</option>
                                        <option :value="target.code" v-for="(target) in dataParam.target" :key="target.code">{{ target.name }}</option>
                                </select>
                            </div>
                            <div class="col-md-2">                                        
                                <label for="">&nbsp;</label>
                                <select class="form-control" v-model="formAssetFooter.facebook_flag">
                                        <option :value=null>Seleccione una opción</option>
                                        <option :value="target.code" v-for="(target) in dataParam.show" :key="target.code">{{ target.name }}</option>
                                </select>
                            </div>      
                            <div class="col-md-3">                                        
                                <label for="">Red Social</label>
                                <input type="text" class="form-control" value="Instagram" readonly />
                            </div>                              
                            <div class="col-md-4">                                        
                                <label for="">Url</label>
                                <input type="text" class="form-control" v-model="formAssetFooter.instagram">
                            </div>                            
                            <div class="col-md-3">                                        
                                <label for="">Target</label>
                                <select class="form-control" v-model="formAssetFooter.instagram_target">
                                        <option :value=null>Activo</option>
                                        <option :value="target.code" v-for="(target) in dataParam.target" :key="target.code">{{ target.name }}</option>
                                </select>
                            </div>
                            <div class="col-md-2">                                        
                                <label for="">&nbsp;</label>
                                <select class="form-control" v-model="formAssetFooter.instagram_flag">
                                        <option :value=null>Seleccione una opción</option>
                                        <option :value="target.code" v-for="(target) in dataParam.show" :key="target.code">{{ target.name }}</option>
                                </select>
                            </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                        </div>
                    </div>     
                    <br />
                    <div class="row">
                        <div class="col-md-6">
                            <button type="submit" class="btn btn-success waves-effect waves-light" @click="submitFormFooter()">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from "vue";
import API from "@/api";
import Swal from 'sweetalert2'


function created(){
    this.getFooter();
}

export default {
    name: 'footer',
    created,
    props: {
        data: Object
    }, 
    setup() {
        
        const dataFooter = ref([]);
        const formAssetFooterError = ref({errors:{}})
        const location = process.env.VUE_APP_BASE_URL;

        const formAssetFooter = ref({
            errors:[],
            id: 1,
            logo: '',
            email: '',
            phone: '',
            extension: '',
            address: '',
            facebook: '',
            facebook_target: '_blank', 
            facebook_flag: false,
            instagram: '',
            instagram_target: '_blank',
            instagram_flag: false

        });    

        const dataParam = ref({
            target : [
                {
                    code: '_blank',
                    name: '_blank',
                }, 
                {
                    code: '_self',
                    name: '_self',
                }, 

            ],
            show: [
                {
                    code: true,
                    name: 'Sí',
                }, 
                {
                    code: false,
                    name: 'No',
                }, 
            ]
        });

        const uploadImage = (event) => {                           
            formAssetFooter.value.logo = event.target.files[0];
        }

       const getFooter = () => {      
            API.get('/api/footer')
            .then(response => {      
                formAssetFooter.value = response.data[0]       
            })
        }

       const submitFormFooter = () => {      
            const form_data = new FormData();        
            for ( var key in formAssetFooter.value ) {
                if (!(key == 'logo' && typeof(formAssetFooter.value[key]) == "string")){
                    form_data.append(key, formAssetFooter.value[key]);
                }
            }           
            API.put('/api/footer', form_data)
            .then(response => {    
                Swal.fire({
                    icon: 'success',
                    title: 'Se guardaron los cambios',
                    text: 'Gestor de ajustes globales',                
                })        
                window.location.reload();
            })
            .catch(error => {                                
                formAssetFooterError.value = error.response.data                    
            })
        }


        return { formAssetFooter, formAssetFooterError,  dataParam, dataFooter,  getFooter, submitFormFooter, uploadImage, location  }
    }
}
</script>

<style scoped>

.imgHeader{
    max-width: 200px;
    height: 70px;
}

.backImage
{
    background-color:lightgray;
}

</style>