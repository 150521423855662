<template>
  <div class="container">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <Breadcrumbs :data="dataViewShop" />
    <Title :data="dataViewTitle" />

    <div class="row">
      <div class="col-lg-10">
        <div class="panel panel-primary">
          <div class="panel-body">
            <form action id="form-toolbar-user" @submit.prevent>
              <div class="col-xs-12">
                <div class="col-sm-2 mr-4">
                  <div class="btn-group mr-1 mb-3">
                    <button
                      type="button"
                      class="btn btn-default dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Cambios estado
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a href="#">Pendiente</a>
                      </li>
                      <li>
                        <a href="#">Aprobado</a>
                      </li>
                      <li>
                        <a href="#">Rechazado</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="btn-group mr-4 mb-3 m-l-10">
                    <button type="button" class="btn btn-success waves-effect waves-light">Aplicar</button>
                  </div>
                </div>

                <div class="col-sm-3">
                  <div class="btn-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="queryStringObj.query"
                      @keyup.enter="searchShop()"
                      placeholder="Nombres de tienda a buscar..."
                    />
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="btn-group mb-3">
                    <select name id class="form-control" v-model="queryStringObj.status">
                      <option value>Seleccione estado</option>
                      <option value="PENDI">Pendiente</option>
                      <option value="REJEC">Rechazado</option>
                      <option value="SUSPE">Suspendido</option>
                      <option value="APPRO">Aprobado</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="btn-group mb-3">
                    <button
                      type="button"
                      class="btn btn-success waves-effect waves-light"
                      @click="searchShop()"
                    >Buscar</button>
                  </div>
                </div>
              </div>
            </form>

            <div class="col-xs-12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <div class="checkbox checkbox-single">
                          <input
                            type="checkbox"
                            id="singleCheckbox1"
                            value="option1"
                            aria-label="Single checkbox One"
                          />
                          <label></label>
                        </div>
                      </th>
                      <th>ID</th>
                      <th>Razón social</th>
                      <th>Usuario</th>
                      <th>Pts reg.</th>
                      <th>Fecha reg.</th>
                      <th>Estado</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, k) in shopData" :key="k" :class="setColorRow(v.status)">
                      <td>
                        <div class="checkbox checkbox-single">
                          <input
                            type="checkbox"
                            id="singleCheckbox1"
                            value="option1"
                            aria-label="Single checkbox One"
                          />
                          <label></label>
                        </div>
                      </td>
                      <td>{{ v.id }}</td>
                      <td>{{ v.business_name }}</td>
                      <td>{{ v.user_register }}</td>
                      <td>{{ v.point_register }}</td>
                      <td>{{ v.created }}</td>
                      <td>
                        <select class="form-control" v-model="v.status">
                          <option value="PENDI">Pendiente</option>
                          <option value="REJEC">Rechazado</option>
                          <option value="SUSPE">Suspendido</option>
                          <option value="APPRO">Aprobado</option>
                        </select>
                      </td>
                      <td>
                        <router-link :to="{ name: 'ShopDetail', params:{ id: v.id } }">
                          <i class="mdi mdi-magnify mdi-18px"></i>&nbsp;
                        </router-link>
                        <i
                          class="mdi mdi-content-save mdi-18px"
                          @click="formShopSave(v.id, v.status)"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-xs-12 text-right">
              <Pagination
                v-model="page"
                :records="records"
                :per-page="limit"
                @paginate="next()"
                :options="options"
              />
              <!-- <ul class="pagination m-b-5">
                                <li>
                                    <a href="#" aria-label="Previous">
                                    <i class="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                <li><a href="#">1</a></li>
                                <li class="active"><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li class="disabled"><a href="#">4</a></li>
                                <li><a href="#">5</a></li>
                                <li>
                                    <a href="#" aria-label="Next">
                                    <i class="fa fa-angle-right"></i>
                                    </a>
                                </li>
              </ul>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "v-pagination-3";
import MyPagination from "@/MyPagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ref } from "vue";
import Swal from "sweetalert2";
import store from "@/store";
import { mapActions, mapState } from "vuex";
import {
  setColorRow,
  setQueryString,
  getFullQueryString
} from "@/utils/Utils.js";
import Breadcrumbs from "@/components/Breadcrumbs";
import Title from "@/components/Title";
import API from "@/api";

export default {
  name: "user",
  components: {
    Breadcrumbs,
    Pagination,
    Title,
    Loading
  },
  setup() {
    var isLoading = ref(false);
    const fullPage = true;

    var paginateString = ref("");
    const page = ref(1);
    const limit = ref(10);
    const records = ref(0);

    var options = ref({
      template: MyPagination
    });

    var queryStringObj = ref({
      status: "",
      query: ""
    });

    const next = () => {
      console.log(page);
      getShop();
    };

    const dataViewShop = ref([
      {
        name: "Tiendas",
        nameRouter: "Shop",
        link: false
      }
    ]);
    const dataViewTitle = ref({
      name: "Tiendas"
    });
    const shopData = ref([]);

    const getShop = () => {
      let gfqs = getFullQueryString({
        queryStringObjParam: queryStringObj.value,
        limitParam: limit.value,
        pageParam: page.value
      });

      store.dispatch("getQueryShopAction", gfqs).then(response => {
        shopData.value = response.data.results;
        records.value = response.data.count;
      });
    };
    const formShopSave = (idParam, statusParam) => {
      isLoading.value = true;
      let userDataUpdate = {
        status: statusParam
      };
      API.put("/api/shop/" + idParam, userDataUpdate)
        .then(response => {
          isLoading.value = false;
          Swal.fire({
            icon: "success",
            title: "Se guardaron los cambios",
            text: "Gestor de puntos"
          });
        })
        .catch(error => {
          isLoading.value = false;
          console.log(error.response.data);
        });
    };

    const searchShop = () => {
      let gfqs = getFullQueryString({
        queryStringObjParam: queryStringObj.value
      });
      store
        .dispatch("getQueryShopAction", gfqs)
        .then(response => {
          shopData.value = response.data.results;
          records.value = response.data.count;
        })
        .catch(error => {
          console.log(error.response.data);
        });
    };

    return {
      isLoading,
      fullPage,
      dataViewShop,
      dataViewTitle,
      shopData,
      getShop,
      formShopSave,
      setColorRow,
      queryStringObj,
      searchShop,
      page,
      next,
      records,
      limit,
      options,
      ...mapActions(["actionGetNotify"])
    };
  },
  created() {
    this.getShop();
    this.actionGetNotify();
  }
};
</script>