<template>
    <div class="col-sm-12">
        <h3 class="page-header m-t-0">{{ data.name }}</h3>
    </div>
</template>

<script>
export default {
    name: "Title",
    props: {
        data: Object
    }
}
</script>