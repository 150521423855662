<template>
    <div class="form-group">
        <div class="col-lg-10">
            <div class="page-content">                                        
                <div class="panel-body">               
                    <div class="row">
                        <div class="col-md-12" v-for="(item, key) in formAssetDataItems" :key="key"> 
                            <div class="form-group col-md-12" style="background-color: #F2F4F4; padding: 30px">
                                <div class="form-group col-md-3" style="display:flex;justify-content:center;align-items:center;">
                                    <img :src="location + (typeof(item.image)=='string'?item.image:'/media/images/head_global_page_internal/no-image.jpg')" class="imgHeader" placeholder="/media/images/head_global_page_internal/no-image.jpg" />
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="">Orden</label>
                                    <input type="number" class="form-control" v-model="item.order"  />
                                </div>
                                <div class="form-group col-md-5">
                                    <label for="">Tamaño recomendado 1440x990px</label>
                                    <input type="file" accept="image/*" class="form__field" @change="uploadImage($event, item)">
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="">&nbsp;</label>
                                    <button type="button" class="btn btn-success waves-effect waves-light" @click="deleteItem(item)">Borrar</button> 
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="">Título</label>
                                    <input type="text" class="form-control" v-model="item.title">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="">Subtítulo</label>
                                    <input type="text" class="form-control" v-model="item.description">
                                </div>
                                <div class="form-group col-md-12" v-if="false">
                                    <label for="">Texto</label>
                                    <input type="text" class="form-control" v-model="item.description">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">Botón: Etiqueta (CTA)</label>
                                    <input type="text" class="form-control" v-model="item.button_text">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">Botón: Url</label>
                                    <input type="text" class="form-control" v-model="item.button_url">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">Botón: Target</label>
                                    <select class="form-control" v-model="item.button_target">
                                        <option :value="target.code" v-for="(target) in dataParam.target" :key="target.code">{{ target.name }}</option>
                                    </select>
                                </div> 
                                <div class="form-group col-md-12">
                                    <label for="">Etiqueta Alt de imagen (SEO)</label>
                                    <input type="text" class="form-control" v-model="item.alt_seo_image">
                                </div>                                                               
                            </div>
                            <div class="form-group col-md-12">
                                <hr/>
                            </div>                                                                 
                        </div>
                    </div>     
                    <div class="row">
                        <div class="col-md-12">
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.image">
                                Imagen - {{ formAssetErrorsNew.errors.image[0] }}
                            </p>
                            
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.title">
                                Título - {{ formAssetErrorsNew.errors.title[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.subtitle">
                                Subtítulo - {{ formAssetErrorsNew.errors.subtitle[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.description">
                                Texto - {{ formAssetErrorsNew.errors.description[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.button_text">
                                Botón: Etiqueta (CTA) - {{ formAssetErrorsNew.errors.button_text[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.button_url">
                                Botón: Url - {{ formAssetErrorsNew.errors.button_url[0] }}
                            </p>         
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.button_target">
                                Botón: Target - {{ formAssetErrorsNew.errors.button_target[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.alt_seo_image">
                                Etiqueta Alt de imagen (SEO) - {{ formAssetErrorsNew.errors.alt_seo_image[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.image">
                                Imagen - {{ formAssetErrorsUpdate.errors.image[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.order">
                                Orden - {{ formAssetErrorsUpdate.errors.order[0] }}
                            </p>                            
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.title">
                                Título - {{ formAssetErrorsUpdate.errors.title[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.subtitle">
                                Subtítulo - {{ formAssetErrorsUpdate.errors.subtitle[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.description">
                                Texto - {{ formAssetErrorsUpdate.errors.description[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.button_text">
                                Botón: Etiqueta (CTA) - {{ formAssetErrorsUpdate.errors.button_text[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.button_url">
                                Botón: Url - {{ formAssetErrorsUpdate.errors.button_url[0] }}
                            </p>         
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.button_target">
                                Botón: Target - {{ formAssetErrorsUpdate.errors.button_target[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.alt_seo_image">
                                Etiqueta Alt de imagen (SEO) - {{ formAssetErrorsUpdate.errors.alt_seo_image[0] }}
                            </p>                                                         
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-6">
                            <button type="button" class="btn btn-success waves-effect waves-light" @click="submitFormNew()"> + Agregar</button>
                                &nbsp;
                            <button type="submit" class="btn btn-success waves-effect waves-light" @click="submitForm()">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from "vue";
import API from "@/api";
import Swal from 'sweetalert2'

function created(){
    this.getData();
}

export default {
    name: 'slideshow',
    created,
    props: {
        data: Object
    },
    setup() {
        
        const location = process.env.VUE_APP_BASE_URL;
        
        const formAssetErrorsNew = ref({errors:{}})
        const formAssetErrorsUpdate = ref({errors:{}})

        const formAssetData  = ref({
            errors:[],
            id : 0,
            image: '',
            order: 1,
            title: '',
            subtitle: '',
            description: '',
            button_text: '',
            button_url: '',
            button_target: '_blank',
            alt_seo_image: '',
        });

        const formAssetDataNew  = ref({
            errors:[],
            id : 0,
            image: '',
            order: 0,
            title: '',
            subtitle: '',
            description: '',
            button_text: '',
            button_url: '',
            button_target: '_blank',
            alt_seo_image: '',
        });
        

        const formAssetDataItems = ref([]);    

        const dataParam = ref({
            target : [
                {
                    code: '_blank',
                    name: '_blank',
                }, 
                {
                    code: '_self',
                    name: '_self',
                }, 

            ],
            show: [
                {
                    code: true,
                    name: 'Sí',
                }, 
                {
                    code: false,
                    name: 'No',
                }, 
            ]
        });

        const deleteItem = (item) =>
        {
            if (item.id>0) {
                API.delete('/api/banner-principal/' + item.id).then(response => { 
                    API.get('/api/banner-principal').then(response => {formAssetDataItems.value = response.data})
                })
             }
            
            const index = formAssetDataItems.value.indexOf(item);
            formAssetDataItems.value.splice(index, 1);

            Swal.fire({
                icon: 'success',
                title: 'Se eliminó el elemento',
                text: 'Gestor de ajustes globales',                
            })
        }

        const uploadImage = (event, item) => {      
            const index = formAssetDataItems.value.indexOf(item);                     
            formAssetDataItems.value[index].image = event.target.files[0];
        }

       const getData = () => {      
            API.get('/api/banner-principal')
            .then(response => {      
                formAssetDataItems.value = response.data
            })
        }

        const submitForm = () => {      
             for ( var pk in formAssetDataItems.value ) {
                var item = formAssetDataItems.value[pk];
                console.log('item', pk, item)
                const form_data = new FormData();        
                for ( var key in item) {
                    if (!(key == 'image' && typeof(item[key]) == "string")){
                        form_data.append(key, item[key]);
                    }
                }
                if (item.id>0)
                {
                    API.put('/api/banner-principal/' + item.id, form_data)
                    .then(response => {})
                    .catch(error => { 
                        Swal.fire({
                            icon: 'error',
                            title: 'Ocurrio un error',
                            text: 'Gestor de ajustes globales',                
                        });
                        formAssetErrorsUpdate.value = error.response.data;
                        return;
                    });                    
                } else {
                    API.post('/api/banner-principal', form_data)
                    .then(response => {})
                    .catch(error => { 
                        Swal.fire({
                            icon: 'error',
                            title: 'Ocurrio un error',
                            text: 'Gestor de ajustes globales',                
                        });
                        formAssetErrorsNew.value = error.response.data;
                        return; 
                    });                    
                }
             }
            Swal.fire({
                icon: 'success',
                title: 'se actualizaron los cambios',
                text: 'Gestor de ajustes globales',                
            })
            window.location.reload();
                  
        }

        const submitFormNew = () => {      
            const item = {
                errors:[],
                id : 0,
                image: '/media/images/head_global_page_internal/no-image.jpg',
                order: 0,
                title: '',
                subtitle: '',
                description: '',
                button_text: '',
                button_url: '',
                button_target: '_blank',
                alt_seo_image: '',
            }

            formAssetDataItems.value.push(item);
        }



        return { 
            formAssetData, 
            formAssetDataNew,
            formAssetDataItems,  
            formAssetErrorsNew,
            formAssetErrorsUpdate,  
            dataParam,  
            location,
            getData, 
            uploadImage, 
            submitForm, 
            submitFormNew, 
            deleteItem,
        }
    }
}
</script>

<style scoped>

.imgHeader{
    max-width: 200px;
    height: 70px;
}

</style>