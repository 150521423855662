<template>
    <div class="form-group">
        <div class="col-lg-10">
            <div class="page-content">                                        
                <div class="panel-body">               
                    <div class="row">
                        <div class="col-md-12" v-for="(item, key) in formAssetDataItems" :key="key"> 
                            <div class="form-group col-md-12" style="background-color: #F2F4F4; padding: 30px">
                                <div class="form-group col-md-3" style="display:flex;justify-content:center;align-items:center;">
                                    <img :src="location + (typeof(item.image)=='string'?item.image:'/media/images/head_global_page_internal/no-image.jpg')" class="imgHeader" placeholder="/media/images/head_global_page_internal/no-image.jpg" />
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="">Menú</label>
                                    <select class="form-control" v-model="item.head_menu_navigation_detail">
                                        <option :value=null>Seleccione un Menu</option>
                                        <option :value="menu.id" v-for="(menu) in formAssetMenuItems" :key="menu.id">{{ menu.name }}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">Tamaño recomendado 1440x990px</label>
                                    <input type="file" accept="image/*" class="form__field" @change="uploadImage($event, item)">
                                </div>
                                <div class="form-group col-md-2">
                                    <button type="button" class="btn btn-success waves-effect waves-light" @click="deleteItem(item)">Borrar</button>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="">Título</label>
                                    <input type="text" class="form-control" v-model="item.title">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="">Subtítulo</label>
                                    <input type="text" class="form-control" v-model="item.subtitle">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="">Etiqueta Alt de imagen (SEO)</label>
                                    <input type="text" class="form-control" v-model="item.alt_seo_image">
                                </div>                                                               
                            </div>
                            <div class="form-group col-md-12">
                                <hr/>
                            </div>                                                                 
                        </div>
                    </div>     
                    <div class="row">
                        <div class="col-md-12">
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.image">
                                Imagen - {{ formAssetErrorsNew.errors.image[0] }}
                            </p>
                            
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.title">
                                Título - {{ formAssetErrorsNew.errors.title[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.subtitle">
                                Subtítulo - {{ formAssetErrorsNew.errors.subtitle[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.description">
                                Texto - {{ formAssetErrorsNew.errors.description[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.button_text">
                                Botón: Etiqueta (CTA) - {{ formAssetErrorsNew.errors.button_text[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsNew.errors.head_menu_navigation_detail">
                                Menu - {{ formAssetErrorsNew.errors.head_menu_navigation_detail[0] }}
                            </p>         
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.image">
                                Imagen - {{ formAssetErrorsUpdate.errors.image[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.order">
                                Orden - {{ formAssetErrorsUpdate.errors.order[0] }}
                            </p>                            
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.title">
                                Título - {{ formAssetErrorsUpdate.errors.title[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.subtitle">
                                Subtítulo - {{ formAssetErrorsUpdate.errors.subtitle[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.description">
                                Texto - {{ formAssetErrorsUpdate.errors.description[0] }}
                            </p>   
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.head_menu_navigation_detail">
                                Menu - {{ formAssetErrorsUpdate.errors.head_menu_navigation_detail[0] }}
                            </p>
                            <p class="form__alert" v-if="formAssetErrorsUpdate.errors.alt_seo_image">
                                Etiqueta Alt de imagen (SEO) - {{ formAssetErrorsUpdate.errors.alt_seo_image[0] }}
                            </p>                                                         
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-6">
                            <button type="button" class="btn btn-success waves-effect waves-light" @click="submitFormNew()"> + Agregar</button>
                                &nbsp;
                            <button type="submit" class="btn btn-success waves-effect waves-light" @click="submitForm()">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from "vue";
import API from "@/api";
import Swal from 'sweetalert2'

function created(){
    this.getData();
    this.getMenu();
}

export default {
    name: 'images',
    created,
    props: {
        data: Object
    },
    setup() {
        
        const location = process.env.VUE_APP_BASE_URL;
        
        const formAssetErrorsNew = ref({errors:{}})
        const formAssetErrorsUpdate = ref({errors:{}})

        const formAssetData  = ref({
            errors:[],
            id : 0,
            head_menu_navigation_detail: 0,
            image: '',
            title: '',
            subtitle: '',
            alt_seo_image: '',
            description: '',
        });

        const formAssetDataNew  = ref({
            errors:[],
            id : 0,
            head_menu_navigation_detail: 0,
            image: '',
            title: '',
            subtitle: '',
            alt_seo_image: '',
            description: '',
        });
        

        const formAssetDataItems = ref([]);    
        const formAssetMenuItems = ref([]);    

        const dataParam = ref({
            target : [
                {
                    code: '_blank',
                    name: '_blank',
                }, 
                {
                    code: '_self',
                    name: '_self',
                }, 

            ],
            show: [
                {
                    code: true,
                    name: 'Sí',
                }, 
                {
                    code: false,
                    name: 'No',
                }, 
            ]
        });

        const deleteItem = (item) =>
        {
            if (item.id>0) {
                API.delete('/api/image-head-page/' + item.id).then(response => { 
                    API.get('/api/image-head-page').then(response => {formAssetDataItems.value = response.data})
                })
             }
            
            const index = formAssetDataItems.value.indexOf(item);
            formAssetDataItems.value.splice(index, 1);

            Swal.fire({
                icon: 'success',
                title: 'Se eliminó el elemento',
                text: 'Gestor de ajustes globales',                
            })
        }

        const uploadImage = (event, item) => {      
            const index = formAssetDataItems.value.indexOf(item);                     
            formAssetDataItems.value[index].image = event.target.files[0];
        }

       const getData = () => {      
            API.get('/api/image-head-page')
            .then(response => {      
                formAssetDataItems.value = response.data
            })
        }

        const submitForm = () => {      
             for ( var pk in formAssetDataItems.value ) {
                var item = formAssetDataItems.value[pk];
                const form_data = new FormData();        
                for ( var key in item) {
                    if (!(key == 'image' && typeof(item[key]) == "string")){
                        form_data.append(key, item[key]);
                    }
                }
                if (item.id>0)
                {
                    API.put('/api/image-head-page/' + item.id, form_data)
                    .then(response => { console.log(response.data) })
                    .catch(error => { 
                        Swal.fire({
                            icon: 'error',
                            title: 'Ocurrio un error',
                            text: 'Gestor de ajustes globales',                
                        });
                        formAssetErrorsUpdate.value = error.response.data;
                        return;
                    });                    
                } else {
                    API.post('/api/image-head-page', form_data)
                    .then(response => {})
                    .catch(error => { 
                        Swal.fire({
                            icon: 'error',
                            title: 'Ocurrio un error',
                            text: 'Gestor de ajustes globales',                
                        });
                        formAssetErrorsNew.value = error.response.data;
                        return; 
                    });                    
                }
             }
            Swal.fire({
                icon: 'success',
                title: 'se actualizaron los cambios',
                text: 'Gestor de ajustes globales',                
            })
            window.location.reload();
                  
        }

        const submitFormNew = () => {      
            const item = {
                errors:[],
                id : 0,
                head_menu_navigation_detail: 0,
                image: '/media/images/head_global_page_internal/no-image.jpg',
                title: '',
                subtitle: '',
                alt_seo_image: '',
                description: '',                
            }

            formAssetDataItems.value.push(item);
        }

        const getMenu = () =>
        {
            API.get('/api/head-menu-navigation-detail')
            .then(response => {      
                formAssetMenuItems.value = response.data
                formAssetMenuItems.value = formAssetMenuItems.value.filter(x=> x.permanent)
            })
        }


        return { 
            formAssetData, 
            formAssetDataNew,
            formAssetDataItems,  
            formAssetErrorsNew,
            formAssetErrorsUpdate,  
            dataParam,  
            location,
            formAssetMenuItems,
            getData, 
            getMenu,
            uploadImage, 
            submitForm, 
            submitFormNew, 
            deleteItem,
        }
    }
}
</script>

<style scoped>

.imgHeader{
    max-width: 200px;
    height: 70px;
}

</style>