<template>
    <div>
        <form role="form" @submit.prevent>
            <div class="form-group">
                <p
                    class="form__alert"
                    v-if="formItemColorError.errors.non_field_errors"
                >{{ formItemColorError.errors.non_field_errors[0] }}</p>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Artículo</label>
                        <v-select
                            label="item"
                            :reduce="item => item.id"
                            v-model="formItemColor.item"
                            :options="dataItem"
                        ></v-select>
                    </div>
                    <p
                        class="form__alert"
                        v-if="formItemColorError.errors.item"
                    >{{ formItemColorError.errors.item[0] }}</p>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Color</label>
                        <v-select
                            label="item"
                            :reduce="item => item.id"
                            v-model="formItemColor.color"
                            :options="dataColor"
                        ></v-select>
                    </div>
                    <p
                        class="form__alert"
                        v-if="formItemColorError.errors.color"
                    >{{ formItemColorError.errors.color[0] }}</p>
                </div>
                <div class="col-sm-1">
                    <div class="form-group">
                        <label for="exampleInputEmail1">&nbsp;</label>
                        <button
                            type="submit"
                            class="btn btn-success waves-effect waves-light m-l-10"
                            @click="submitFormItemColor()"
                        >Crear</button>
                    </div>
                </div>
            </div>
        </form>
        <div class="col-xs-12">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Artículo</th>
                            <th>Color</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v, k) in dataItemColor" :key="k">
                            <template v-if="!v.editMode">
                                <td>{{ v.item_detail.code }}</td>
                                <td>{{ v.color_detail.code }}</td>
                            </template>
                            <template v-else>
                                <td>
                                    <v-select
                                        label="item"
                                        :reduce="item => item.id"
                                        v-model="v.item"
                                        :options="dataItem"
                                    ></v-select>
                                    <p class="form__alert"
                                        v-if="v.editMode && formUpdateItemColorError.errors.item"
                                    >{{ formUpdateItemColorError.errors.item[0] }}</p>
                                    <!-- <input type="text" v-model="v.item_detail.code" class="form-control" /> -->
                                </td>
                                <td>
                                    <!-- <input
                                        type="text"
                                        v-model="v.color_detail.code"
                                        class="form-control"
                                    />-->
                                    <v-select
                                        label="item"
                                        :reduce="item => item.id"
                                        v-model="v.color"
                                        :options="dataColor"
                                    ></v-select>
                                    <p class="form__alert"
                                        v-if="v.editMode && formUpdateItemColorError.errors.color"
                                    >{{ formUpdateItemColorError.errors.color[0] }}</p>
                                </td>
                            </template>

                            <td>
                                <i
                                    v-if="!v.editMode"
                                    @click="editItemColor(v)"
                                    class="mdi mdi-pencil mdi-18px"
                                ></i>&nbsp;
                                <i
                                    v-else
                                    @click="updateItemColor(v)"
                                    class="mdi mdi-content-save mdi-18px"
                                ></i>&nbsp;
                                <i
                                    @click="deleteItemColor(v)"
                                    class="mdi mdi-close-circle-outline mdi-18px"
                                ></i>
                            </td>
                            <p class="form__alert" v-if="v.editMode && formUpdateItemColorError.errors.non_field_errors"
                            >{{ formUpdateItemColorError.errors.non_field_errors[0] }}</p>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import Swal from "sweetalert2";
import store from "@/store";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "ItemColor",
    components: {
        vSelect
    },
    setup() {
        const formItemColor = ref({
            item: "",
            color: ""
        });
        const formItemColorError = ref({ errors: {} });
        const formUpdateItemColorError = ref({ errors: {} });
        const dataItemColor = ref([]);
        const dataItem = ref([]);
        const dataColor = ref([]);
        const editMode = ref(false);

        const editItemColor = obj => {
            obj["editMode"] = true;
            formUpdateItemColorError.value = { errors: {} };
        };

        const submitFormItemColor = () => {
            store
                .dispatch("postItemColor", formItemColor.value)
                .then(response => {
                    //debugger;
                    formItemColor.value = {};
                    getItemColor();
                    Swal.fire({
                        icon: "success",
                        title: "Se guardaron los cambios",
                        text: "Artículo"
                    });
                })
                .catch(error => {
                    console.log(error.response.data, "aaaa");
                    formItemColorError.value = error.response.data;
                });
        };
        const getItem = () => {
            let queryString = {
                for_search: ""
            };
            store
                .dispatch("getItem", queryString)
                .then(response => {
                    dataItem.value = response.data;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        };
        const getColor = () => {
            let queryString = {
                for_search: ""
            };
            store
                .dispatch("getColor", queryString)
                .then(response => {
                    dataColor.value = response.data;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        };
        const getItemColor = () => {
            store.dispatch("getItemColor").then(response => {
                dataItemColor.value = response.data;
            });
        };
        const updateItemColor = obj => {
            store
                .dispatch("putItemColor", obj)
                .then(response => {
                    obj["editMode"] = false;
                    getItemColor();
                    console.log("updated!");
                })
                .catch(error => {
                    formUpdateItemColorError.value = error.response.data;
                    console.log(error.response.data);
                });
        };

        const deleteItemColor = obj => {
            Swal.fire({
                title: "¿Desea eliminar este artículo y color?",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: "SÍ"
            }).then(async result => {
                if (result.isConfirmed) {
                    await store.dispatch("deleteItemColor", obj);
                    getItemColor();
                }
            });
        };

        return {
            submitFormItemColor,
            deleteItemColor,
            updateItemColor,
            dataItemColor,
            formItemColor,
            formItemColorError,
            formUpdateItemColorError,
            editMode,
            editItemColor,
            getItemColor,
            getItem,
            getColor,
            dataItem,
            dataColor
        };
    },
    mounted() {
        this.getItemColor();
        this.getItem();
        this.getColor();
    }
};
</script>