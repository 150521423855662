<template>
    <div class="container">
        
        <Breadcrumbs :data=dataViewBreadcrumbs />    
        <Title :data=dataViewTitle />

        <div class="row">
            <div class="col-lg-10">
                <div class="panel-group" id="accordion-test-2">
                    <div class="panel panel-info panel-color">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion-test-2" href="#collapseOne-2" aria-expanded="false" class="collapsed">
                                    COLORES
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne-2" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <div class="col-md-10">
                                    <div class="panel panel-primary">                                    
                                        <div class="panel-body">                                                                                        
                                            <Color />                                            
                                        </div>
                                    </div>
                                </div>
                                                                                            
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-info panel-color">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion-test-2" href="#collapseTwo-2" class="collapsed" aria-expanded="false">
                                    ARTÍCULOS
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo-2" class="panel-collapse collapse">
                            <div class="panel-body">
                                <div class="col-md-10">                                    
                                    <div class="panel panel-primary">                                                                            
                                        <div class="panel-body">                                                                                        
                                            <Item />
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-info panel-color">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion-test-2" href="#collapseThree" class="collapsed" aria-expanded="false">
                                    ARTÍCULOS X COLOR
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" class="panel-collapse collapse">
                            <div class="panel-body">
                                <div class="col-md-10">                                    
                                    <div class="panel panel-primary">                                                                            
                                        <div class="panel-body">                                                                                        
                                            <ItemColor />
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-info panel-color">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion-test-2" href="#collapseFour" class="collapsed" aria-expanded="false">
                                    ARTÍCULOS X LÍNEA
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFour" class="panel-collapse collapse">
                            <div class="panel-body">
                                <div class="col-md-10">                                    
                                    <div class="panel panel-primary">                                                                            
                                        <div class="panel-body">                                                                                        
                                            <LineItemColor />
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import { ref } from "vue";
import Swal from 'sweetalert2'
import Datepicker from 'vue3-datepicker'
import { mapActions, mapState } from "vuex";
import Breadcrumbs from '@/components/Breadcrumbs'
import Title from '@/components/Title'
import API from "@/api";
import Color from "@/components/Color";
import Item from "@/components/Item";
import ItemColor from "@/components/ItemColor";
import LineItemColor from "@/components/LineItemColor";

const picked = ref(new Date())

export default {
    name: "ItemLineManager",
    components: {
        Breadcrumbs,
        Title,      
        Color,  
        Item,
        ItemColor,
        LineItemColor,
    },
    setup() {

        const dataBonoSpecial = ref([])
        const formBonoSpecial = ref({
            bonus_description: "",
            bonus_point: "",
            bonus_date: ""
        })
        const editMode = ref(false)

        const formBonoSpecialError = ref({errors:{}})

        const dataConf = ref([
            {
                bonus_registration_completed: 0, 
                bonus_point_image_project:0 
            }
        ])

        const formEquivalence = ref({            
            level: {1: "0", 2: "0", 3: "0", 4: "0", 5:"0"},
            item: null,
            code: ""
        })
        const dataLevel = ref([])

        const dataViewBreadcrumbs = ref({
            name: 'Club Barrington / Línea de artículos'
        })
        const dataViewTitle = ref({
            name: 'Línea de artículos'
        })

        const dataProductForExchangeItem = ref([])
        const dataPointForExchange = ref([])

        const editBonoSpecial = (obj) => {                        
            obj['editMode'] = true
        }
        const updateBonoSpecial = (obj) => {
            //debugger
            API.put("/api/bono-special/" + obj.pk, obj)
                .then(response => {
                    obj['editMode'] = false
                    console.log('updated!')
                })
                .catch(error => {
                    console.log(error.response.data, 'xxxxxxxxx')
                })                

        }

        const deleteBonoSpecial = (obj) => {
            Swal.fire({
                title: '¿Desea eliminar la bonificación especial?',                
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: `SI`,
                
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        console.log(obj, 'ELEME')                        
                        await API.delete("/api/bono-special/" + obj.pk)                        
                        getBonoSpecial()
                    }
                })
        }
        
        const editUpdateFormEquivalence = (obj) => {                        
            obj['editMode'] = true
        }
        
        const submitUpdateFormEquivalence = async (obj) => {            
            obj.items.forEach(element => {                
                let payload = { point: element.point }
                API.put("/api/point-for-exchange/" + element.pk, payload)
                    .then(response => {
                        console.log('updated!')
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    })                
            });
            obj['editMode'] = false            

        }

        const deleteFormEquivalence = (obj) => {
            Swal.fire({
                title: '¿Desea eliminar la equivalencia?',                
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: `SI`,
                
                }).then(async (result) => {
                    if (result.isConfirmed) {                        
                        await API.delete("/api/product-for-exchange/" + obj.pk)                        
                        getPointForExchange()
                    }
                })
        }

        const submitFormBonoSpecial = () => {                        
            //debugger
            formBonoSpecial.value.bonus_date = formBonoSpecial.value.bonus_date.toISOString().split('T')[0]
            API.post("/api/bono-special", formBonoSpecial.value)
                
                .then(response => {
                    formBonoSpecial.value = {}
                    formBonoSpecialError.value = {errors: {}}       
                    getBonoSpecial()             
                    Swal.fire({
                        icon: 'success',
                        title: 'Se guardaron los cambios',
                        text: 'Bono especial',                        
                    })
                })
                .catch(error => {                    
                    formBonoSpecialError.value = error.response.data
                })
        }

        const getBonoSpecial = () => {
            API.get("/api/bono-special")
                .then(response => {
                    dataBonoSpecial.value = response.data
                })
        }

        var dataTempConfReg = []
        const saveBonusRegistrationCompleted = () => {                        
            API.get("/api/conf")
                .then(response => {                    
                    dataTempConfReg = response.data                    
                    /*obtengo la data del server y no del input para saber realmente si hubo data y con ello saber si 
                    usar post o put */
                    //debugger                    
                    if (dataTempConfReg.length > 0){                        
                        let payload = { bonus_registration_completed: dataConf.value[0].bonus_registration_completed }
                        API.put("/api/conf", payload)
                            .then(response => {
                                console.log('update!')
                            })
                            .catch(error => {
                                console.log(error.response.data)
                            })
                    } else {
                        let payload = { bonus_registration_completed: dataConf.value[0].bonus_registration_completed }
                        API.post("/api/conf", payload)
                            .then(response => {
                                console.log('created!')
                            })
                            .catch(error => {
                                console.log(error.response.data)
                            })                        
                    }                    
                    Swal.fire({
                        icon: 'success',
                        title: 'Se guardaron los cambios',
                        text: 'Niveles y puntos',                        
                    })                            
                })                        
        }

        var dataTempConfImg = []
        const saveBonusImageProject = () => {                        
            API.get("/api/conf")
                .then(response => {                    
                    dataTempConfImg = response.data                    
                    /*obtengo la data del server y no del input para saber realmente si hubo data y con ello saber si 
                    usar post o put */
                    //debugger                    
                    if (dataTempConfImg.length > 0){                        
                        let payload = { bonus_point_image_project: dataConf.value[0].bonus_point_image_project }
                        API.put("/api/conf", payload)
                            .then(response => {
                                console.log('update!')
                            })
                            .catch(error => {
                                console.log(error.response.data)
                            })
                    } else {
                        let payload = { bonus_point_image_project: dataConf.value[0].bonus_point_image_project }
                        API.post("/api/conf", payload)
                            .then(response => {
                                console.log('created!')
                            })
                            .catch(error => {
                                console.log(error.response.data)
                            })                        
                    }    
                    Swal.fire({
                        icon: 'success',
                        title: 'Se guardaron los cambios',
                        text: 'Niveles y puntos',                        
                    })                                            
                })                        
        }

        const getConf = () => {

            API.get("/api/conf")
                .then(response => {
                    //debugger
                    if (response.data.length > 0) {
                        dataConf.value = response.data
                        
                    }
                    
                })
        }
        
        const getDataProductForExchange = () => {
            API.get("/api/product-for-exchange")
                .then(response => {
                    //debugger;
                    dataProductForExchangeItem.value = response.data
                })
        }
        const submitFormEquivalence = async () => {
            
            for ( var key in formEquivalence.value.level ) {
                //console.log(formEquivalence.value.item)
                let data = {product_for_exchange: formEquivalence.value.item, level: key, point: formEquivalence.value.level[key]}                
                await API.post("/api/point-for-exchange", data)
                    .then(response => {
                        console.log('created!')
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    })

            }
            getPointForExchange()
            console.log('jala!')

        }
        const getPointForExchange = () => {
            API.get("/api/point-for-exchange")
                .then(response => {
                    //debugger
                    dataPointForExchange.value = response.data
                })
            }            
        
        const getLevel = () => {
            API.get("/api/level")
                .then(response => {
                    dataLevel.value = response.data
                })
        }

        const updateDataLevel = () => {
            //console.log(dataLevel, 'level')
            var len = dataLevel.value.length
            var iDataLevel = 0            
            dataLevel.value.map(obj => {                
                let payload = { from_point: obj.from_point }                
                API.put("/api/level/" + obj.id, payload)
                    .then(response => {                        
                        iDataLevel++                        
                        if (len == iDataLevel) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Se guardaron los cambios',
                                text: 'Hitos',                        
                            })                            
                        }
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    })
            })
        }                

        const formAssetForExchange = () => {
            Swal.fire({
                title: 'Nuevo producto',
                html: `<input type="text" id="code" class="form-control" placeholder="Código">
                <input type="text" id="title" class="form-control" placeholder="Nombre">`,
                confirmButtonText: 'Guardar',
                focusConfirm: false,
                preConfirm: () => {
                    const code = Swal.getPopup().querySelector('#code').value
                    const title = Swal.getPopup().querySelector('#title').value
                    if (!code || !title) {
                    Swal.showValidationMessage(`Agregue un código o nombre`)
                    }
                    return { code: code, title: title }
                }
                }).then((result) => {
                    
                    API.post("/api/product-for-exchange", result.value)
                        .then(response => {
                            
                            getDataProductForExchange();
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        })
                /* Swal.fire(`
                    Login: ${result.value.code}
                    Password: ${result.value.password}
                `.trim()) */
                })
        }

        return { 
            deleteBonoSpecial,
            editBonoSpecial,
            updateBonoSpecial,
            deleteFormEquivalence,
            editUpdateFormEquivalence,
            submitUpdateFormEquivalence,
            dataLevel,
            dataViewBreadcrumbs, 
            dataViewTitle, 
            formEquivalence, 
            submitFormEquivalence, 
            getDataProductForExchange, 
            dataProductForExchangeItem,
            dataPointForExchange,
            getPointForExchange,
            getLevel,
            updateDataLevel,
            formAssetForExchange,                        
            getConf,
            saveBonusRegistrationCompleted,
            saveBonusImageProject,                                                                                      
            dataConf,
            formBonoSpecial,
            formBonoSpecialError,
            submitFormBonoSpecial,
            dataBonoSpecial,
            getBonoSpecial,
            picked,
            ...mapActions(['actionGetNotify']) 
        }
        
    },
    async mounted() {
        this.getDataProductForExchange();
        this.getPointForExchange();
        this.getLevel();
        this.getConf();
        this.getBonoSpecial();
        this.actionGetNotify();
    }

}
</script>
