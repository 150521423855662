<template>
    <div>        
        <table class="table">
            <thead>
                <tr>
                    <th>Artículo</th>
                    <th>Metros</th>
                </tr>                
            </thead>    
            <tbody>
                <tr v-for="(v, k) in voucherItem" :key="k" >
                    <td>{{ v.item_x_color.item_detail.code }}-{{ v.item_x_color.color_detail.code }}</td>
                    <td>{{ v.meter }}</td>
                </tr>
            </tbody>

        </table>

    </div>
</template>

<script>
import { ref } from "vue";
import store from "@/store";
import router from "@/router";

export default {
    name: "VoucherItem",
    props: {
        pointActivityId: Number
    },
    components: {

    },
    setup() {
        const voucherItem = ref([])
        
        const getPointActivityDetailFilterPointActivity = (payload) => {
            store.dispatch("getPointActivityDetailFilterPointActivity", payload)
            .then(response => {
                voucherItem.value = response.data
            })
        }
        
        return {
            voucherItem,
            getPointActivityDetailFilterPointActivity,

        }
    },
    created() {
        //debugger
        let payload = {
            pointActivityId: this.pointActivityId,
        }
        this.getPointActivityDetailFilterPointActivity(payload)
    }
}
</script>