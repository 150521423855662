import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import store from '@/store';
import Home from '../views/Home.vue'



const history = createWebHistory();
const router = createRouter({
    history,
    routes: [
    {
        path: "/login",
        name: "Login",
        component: () =>  import("@/views/Login"),
        meta: {
            plainLayout: true,
        },
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            plainLayout: false,
            pathPrivate: true
        },
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
    },
    {
        path: '/analitica',
        name: 'Analytic',
        component: () => import("@/views/Analytic"),
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
    },
    {
        path: '/niveles-y-puntos',
        name: 'LevelPoint',
        component: () => import("@/views/LevelPoint"),
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
    },
    {
        path: '/gestor-puntos',
        name: 'PointManager',
        component: () => import("@/views/PointManager"),        
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
    },
    {
        path: '/gestor-puntos/:id',
        name: 'PointManagerDetail',
        component: () => import("@/views/PointManagerDetail"),        
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
    },
    {
        path: '/usuarios',
        name: 'User',
        component: () => import("@/views/User"),
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
    },
    {
        path: '/usuarios/:id',
        name: 'UserDetail',
        component: () => import("@/views/UserDetail"),
        meta: {
            plainLayout: false,
            pathPrivate: true
        }

    },
    {
        path: '/tiendas',
        name: 'Shop',
        component: () => import("@/views/Shop"),
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
    },
    {
        path: '/tiendas/:id',
        name: 'ShopDetail',
        component: () => import("@/views/ShopDetail"),
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
    },
    {
        path: '/gestor-canjes',
        name: 'AssetExchangeManager',
        component: () => import("@/views/AssetExchangeManager"),
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
    },
    {
        path: '/gestor-canjes/:id',
        name: 'AssetExchangeManagerDetail',
        component: () => import("@/views/AssetExchangeManagerDetail"),
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
        
    },
    {
        path: '/canjes',
        name: 'AssetExchange',
        component: () => import("@/views/AssetExchange"),
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
    },
    {
        path: '/canjes/add',
        name: 'AssetExchangeAdd',
        component: () => import("@/views/AssetExchangeAdd"),
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
    },
    {
        path: '/canjes/:id',
        name: 'AssetExchangeEdit',
        component: () => import("@/views/AssetExchangeAdd"),
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
    },
    {
        path: '/linea-articulo',
        name: 'ItemLineManager',
        component: () => import("@/views/ItemLineManager"),
        meta: {
            plainLayout: false,
            pathPrivate: true
        }
    }

    
]
});

//store.state.dataToken.token
router.beforeEach((to, from, next) => {
    const pathIsPrivate = to.matched.some(record => record.meta.pathPrivate);
    //debugger
    
    if (pathIsPrivate && store.state.dataToken.token === undefined) {
        
        next({ name: 'Login' })
    } else {
        
        next();
    }
})

export default router;

