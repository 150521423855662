<template>
    <!-- <div id="wrapper"> -->
    
    
        
            
                <div id="wrapper" v-bind:class="{'force enlarged': store.state.showSideBar}" v-if="!route.meta.plainLayout">
                    <!-- Top Bar Start -->
                    <TopBar />
                    <!-- Top Bar End -->
                    <!-- ========== Left Sidebar Start ========== -->
                    <LeftSideBar />
                    <!-- Left Sidebar End -->
                    <!-- Start right Content here -->
                    <div class="content-page">            
                        <!-- Start content -->
                        <div class="content">               
                            <router-view />                
                        </div> <!-- content -->
                        <footer class="footer">
                            2021 © Club Barrington - Administrador
                        </footer>
                    </div>
                    <!-- End Right content here -->
                </div>
                <div v-else>
                    <router-view />                
                </div>
            
            
        
</template>

<script>
import router from "@/router";
import { useRoute } from 'vue-router'
import store from "@/store";
//import Breadcrumbs from "@/components/Breadcrumbs";
//import Footer from "@/components/Footer";
import TopBar from "@/components/TopBar";
import LeftSideBar from "@/components/LeftSideBar";
import { mapActions } from "vuex";

//import { MatchMedia } from "vue-component-media-queries";

export default {
    name: "App",
    components: {
        //Breadcrumbs,
        //Footer,
        TopBar,
        LeftSideBar,

    },

    setup() {
        const route = useRoute()


        const data = router.currentRoute._value;
        
        return { store, router, data, route, ...mapActions(['readToken']) }
    },
    created() {
        
        this.readToken();
    }
    
}
</script>
