<template>
    <div class="topbar">
            <!-- LOGO -->
        <div class="topbar-left">
            <div class="text-center">
                <a href="index.html" class="logo"><img src="/assets/images/logo.svg" height="50"></a>
                <!-- <a href="index.html" class="logo-sm"><img src="assets/images/logo_sm.png" height="36"></a> -->
            </div>
        </div>
        <!-- Button mobile view to collapse sidebar menu -->
        <div class="navbar navbar-default" role="navigation">
            <div class="container">
                <div class="">
                    <div class="pull-left">
                        <button type="button" class="button-menu-mobile open-left waves-effect waves-light" @click="actionSetState({'showSideBar': !store.state.showSideBar})">
                            <i class="ion-navicon"></i>
                        </button>
                        <span class="clearfix"></span>
                    </div>
                    <!-- <form class="navbar-form pull-left" role="search">
                        <div class="form-group">
                            <input type="text" class="form-control search-bar" placeholder="Search...">
                        </div>
                        <button type="submit" class="btn btn-search"><i class="fa fa-search"></i></button>
                    </form> -->

                    <ul class="nav navbar-nav navbar-right pull-right">
                        <li class="dropdown hidden-xs">
                            
                            <a href="#" data-target="#" class="dropdown-toggle waves-effect waves-light" data-toggle="dropdown" aria-expanded="true">
                                <i class="fa fa-bell"></i> <span class="badge badge-xs badge-danger" v-if="notify['Total']"> {{ notify["Total"] }}</span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-lg" v-if="notify['Total']">
                                <li class="text-center notifi-title">Notificaciones<span class="badge badge-xs badge-success"></span></li>
                                <li class="list-group">
                                    <!-- list item-->
                                    <a href="javascript:void(0);" class="list-group-item" v-if="notify['Usuarios registrados']" @click="redirectUser()">
                                        <div class="media">
                                            <div class="media-heading">Usuarios</div>
                                            <p class="m-0">
                                            <small>Tiene {{ notify["Usuarios registrados"] }} nuevos usuarios registrados.</small>
                                            </p>
                                        </div>
                                    </a>
                                    <!-- list item-->
                                    <a href="javascript:void(0);" class="list-group-item" v-if="notify['Tiendas registradas']" @click="redirectShop()">
                                        <div class="media">
                                            <div class="media-body clearfix">
                                            <div class="media-heading">Tiendas</div>
                                            <p class="m-0">
                                                <small>Tiene {{ notify["Tiendas registradas"] }} nuevas tiendas registradas.</small>
                                            </p>
                                            </div>
                                        </div>
                                    </a>
                                    <!-- list item-->
                                    <a href="javascript:void(0);" class="list-group-item" v-if="notify['Solicitud de canjes']" @click="redirectExchange()">
                                        <div class="media">
                                            <div class="media-body clearfix">
                                            <div class="media-heading">Canjes</div>
                                            <p class="m-0">
                                                <small>Tiene {{ notify["Solicitud de canjes"] }} solicitudes de canje</small>
                                            </p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0);" class="list-group-item" v-if="notify['Solicitud de puntos']" @click="redirectPoint()">
                                        <div class="media">
                                            <div class="media-body clearfix">
                                            <div class="media-heading">Puntos</div>
                                            <p class="m-0">
                                                <small>Tiene {{ notify["Solicitud de puntos"] }} solicitudes de puntos</small>
                                            </p>
                                            </div>
                                        </div>
                                    </a>
                                    <!-- last list item -->
                                    <!-- <a href="javascript:void(0);" class="list-group-item">
                                        <small class="text-primary">See all notifications</small>
                                    </a> -->
                                </li>
                            </ul>
                        </li>
                        <li class="hidden-xs">
                            <!-- <a href="#" id="btn-fullscreen" class="waves-effect waves-light"><i class="fa fa-crosshairs"></i></a> -->
                        </li>
                        <li class="dropdown">
                            <a href="" class="dropdown-toggle profile waves-effect waves-light" data-toggle="dropdown" aria-expanded="true">
                                <img src="/favicon-16x16.svg" alt="user-img" class="img-circle"> 
                            </a>
                            <ul class="dropdown-menu">
                                <!-- <li><a href="javascript:void(0)"> Profile</a></li>
                                <li><a href="javascript:void(0)"><span class="badge badge-success pull-right">5</span> Settings </a></li>
                                <li><a href="javascript:void(0)"> Lock screen</a></li>
                                <li class="divider"></li> -->
                                <li><a href="javascript:void(0)" @click="closeSession"> Logout</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <!--/.nav-collapse -->
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from "vue";
import { mapActions, mapState } from "vuex";
import router from "@/router";
import store from '@/store';
//import { useStore } from 'vuex'

import API from "@/api";
export default {
    
    setup(){
        //const store = useStore()
        const prefixStatic = API.defaults.prefixStatic || ""
        const dataNotify = ref({})            

        const redirectUser = () => {            
            store.dispatch("putNotify", { param: "REGUS" })
                .then(response => {
                    router.push({ name: 'User' })
                })
        }
        const redirectShop = () => {        
            store.dispatch("putNotify", { param: "REGSH" })
                .then(response => {
                    router.push({ name: 'Shop' })
                })
        }
        const redirectExchange = () => {            
            store.dispatch("putNotify", { param: "REQEX" })
                .then(response => {
                    router.push({ name: 'AssetExchangeManager' })
                })
        }
        const redirectPoint = () => {            
            store.dispatch("putNotify", { param: "REQPO" })
                .then(response => {
                    router.push({ name: 'PointManager' })
                })
        }        

        return { 
            store,         
            redirectUser,
            redirectShop,
            redirectExchange,
            redirectPoint,
            dataNotify,             
            prefixStatic, 
            ...mapActions(['actionSetState', 'closeSession', 'actionGetNotify']),
            
        }

    },
    mounted() {        
        this.actionGetNotify()        
    },
    computed: {
        ...mapState(['notify'])
    }
    
    
}
</script>
