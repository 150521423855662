<template>
    <div>
        <form role="form" @submit.prevent>
            <div class="form-group">
                <p
                    class="form__alert"
                    v-if="formLineItemColorError.errors.non_field_errors"
                >{{ formLineItemColorError.errors.non_field_errors[0] }}</p>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Línea de artículo</label>
                        <v-select
                            label="item"
                            :reduce="item => item.id"
                            v-model="formLineItemColor.product_for_exchange"
                            :options="dataLineItem"
                        ></v-select>
                    </div>
                    <p
                        class="form__alert"
                        v-if="formLineItemColorError.errors.item"
                    >{{ formLineItemColorError.errors.item[0] }}</p>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Artículo</label>
                        <v-select
                            label="item"
                            :reduce="item => item.id"
                            v-model="formLineItemColor.item_x_color"
                            :options="dataItemColor"
                        ></v-select>
                    </div>
                    <p
                        class="form__alert"
                        v-if="formLineItemColorError.errors.color"
                    >{{ formLineItemColorError.errors.color[0] }}</p>
                </div>
                <div class="col-sm-1">
                    <div class="form-group">
                        <label for="exampleInputEmail1">&nbsp;</label>
                        <button
                            type="submit"
                            class="btn btn-success waves-effect waves-light m-l-10"
                            @click="submitFormLineItemColor()"
                        >Crear</button>
                    </div>
                </div>
            </div>
        </form>
        <div class="col-xs-12">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Línea de artículo</th>
                            <th>Artículo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v, k) in dataLineItemColor" :key="k">
                            <template v-if="!v.editMode">
                                <td>{{ v.product_for_exchange_detail.item }}</td>                                
                                <td>{{ v.item_x_color_detail.item_detail.code }}-{{ v.item_x_color_detail.color_detail.code }}</td>
                            </template>
                            <template v-else>
                                <td>
                                    <v-select
                                        label="item"
                                        :reduce="item => item.id"
                                        v-model="v.item"
                                        :options="dataItem"
                                    ></v-select>
                                    <p class="form__alert"
                                        v-if="v.editMode && formUpdateLineItemColorError.errors.item"
                                    >{{ formUpdateLineItemColorError.errors.item[0] }}</p>
                                    <!-- <input type="text" v-model="v.item_detail.code" class="form-control" /> -->
                                </td>
                                <td>
                                    <!-- <input
                                        type="text"
                                        v-model="v.color_detail.code"
                                        class="form-control"
                                    />-->
                                    <v-select
                                        label="item"
                                        :reduce="item => item.id"
                                        v-model="v.color"
                                        :options="dataColor"
                                    ></v-select>
                                    <p class="form__alert"
                                        v-if="v.editMode && formUpdateLineItemColorError.errors.color"
                                    >{{ formUpdateLineItemColorError.errors.color[0] }}</p>
                                </td>
                            </template>

                            <td>
                                <i
                                    v-if="!v.editMode"
                                    @click="editLineItemColor(v)"
                                    class="mdi mdi-pencil mdi-18px"
                                ></i>&nbsp;
                                <i
                                    v-else
                                    @click="updateLineItemColor(v)"
                                    class="mdi mdi-content-save mdi-18px"
                                ></i>&nbsp;
                                <i
                                    @click="deleteLineItemColor(v)"
                                    class="mdi mdi-close-circle-outline mdi-18px"
                                ></i>
                            </td>
                            <p class="form__alert" v-if="v.editMode && formUpdateLineItemColorError.errors.non_field_errors"
                            >{{ formUpdateLineItemColorError.errors.non_field_errors[0] }}</p>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import Swal from "sweetalert2";
import store from "@/store";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "LineItemColor",
    components: {
        vSelect
    },
    setup() {
        const formLineItemColor = ref({
            product_for_exchange: "",
            item_x_color: ""
        });
        const formLineItemColorError = ref({ errors: {} });
        const formUpdateLineItemColorError = ref({ errors: {} });        
        const dataItem = ref([]);
        const dataItemColor = ref([]);
        const dataLineItemColor = ref([]);
        const dataLineItem = ref([]);
        const editMode = ref(false);

        const editLineItemColor = obj => {
            obj["editMode"] = true;
            formUpdateLineItemColorError.value = { errors: {} };
        };

        const submitFormLineItemColor = () => {
            store
                .dispatch("postLineItemColor", formLineItemColor.value)
                .then(response => {
                    //debugger;
                    formLineItemColor.value = {};
                    getLineItemColor();
                    Swal.fire({
                        icon: "success",
                        title: "Se guardaron los cambios",
                        text: "Artículo"
                    });
                })
                .catch(error => {
                    console.log(error.response.data, "aaaa");
                    formLineItemColorError.value = error.response.data;
                });
        };
        
        const getItemColor = () => {
            /* let queryString = {
                for_search: ""
            }; */
            store
                .dispatch("getItemColor")
                .then(response => {
                    dataItemColor.value = response.data;
                    dataItemColor.value.map(v => {
                        v['item'] = `${v.item_detail.code}-${v.color_detail.code}` 
                    })
                    //let ss = dataItemColor.value['item'] = 'aaaa'
                    //dataItemColor.value = ss
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        };
        const getLineItemColor = () => {
            let queryString = {
                for_search: ""
            };
            store.dispatch("getLineItemColor", queryString).then(response => {
                dataLineItemColor.value = response.data;
            });
        };
        const getLineItem = () => {
            let queryString = {
                for_search: ""
            };
            store.dispatch("getLineItem", queryString).then(response => {
                dataLineItem.value = response.data;
            });
        };
        const updateLineItemColor = obj => {
            store
                .dispatch("putLineItemColor", obj)
                .then(response => {
                    obj["editMode"] = false;
                    getLineItemColor();
                    console.log("updated!");
                })
                .catch(error => {
                    formUpdateLineItemColorError.value = error.response.data;
                    console.log(error.response.data);
                });
        };

        const deleteLineItemColor = obj => {
            Swal.fire({
                title: "¿Desea eliminar este artículo y color?",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: "SÍ"
            }).then(async result => {
                if (result.isConfirmed) {
                    await store.dispatch("deleteLineItemColor", obj);
                    getLineItemColor();
                }
            });
        };

        return {
            submitFormLineItemColor,
            deleteLineItemColor,
            updateLineItemColor,
            dataLineItemColor,
            dataLineItem,
            formLineItemColor,
            formLineItemColorError,
            formUpdateLineItemColorError,
            editMode,
            editLineItemColor,
            getLineItemColor,  
            getLineItem,           
            getItemColor,           
            dataItemColor,                   
        };
    },
    mounted() {
        this.getLineItemColor();
        this.getLineItem();
        this.getItemColor();
    }
};
</script>