<template>
  <div class="container">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="fullPage" />
    <Breadcrumbs :data="dataViewUserDetail" />
    <Title :data="dataViewTitle" />
    <div class="row">
      <div class="col-lg-10">
        <div class="page-content">
          <div class="tabbed">
            <input type="radio" id="tab1" name="css-tabs" checked />
            <input type="radio" id="tab2" name="css-tabs" />
            <input type="radio" id="tab3" name="css-tabs" />
            <ul class="tabs">
              <li class="tab">
                <label for="tab1">Información personal</label>
              </li>
              <li class="tab">
                <label for="tab2">Registro histórico de puntos</label>
              </li>
              <li class="tab">
                <label for="tab3">Registro histórico de canjes</label>
              </li>
            </ul>
            <div class="tab-content user-detail">
              <div class="panel-body">
                <div class="col-md-10">
                  <div class="row">
                    <UserResumePoint :data="dataUser" />
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="col-md-2">
                  <div class="form-group">
                    <br />
                    <img
                      :src="dataUser.photo ? `${baseURL}${dataUser.photo}` : 'https://dummyimage.com/150x150/5974ba/ffffff.png'"
                      class="image-profile"
                    />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Estado</label>
                          <select class="form-control" v-model="dataUser.status">
                            <option value="PENDI">Pendiente</option>
                            <option value="REJEC">Rechazado</option>
                            <option value="SUSPE">Suspendido</option>
                            <option value="APPRO">Aprobado</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Acciones</label>
                          <p class="p-t-8">
                            <i
                              class="mdi mdi-content-save mdi-18px"
                              @click="actionUserUpdateStatus(dataUser)"
                            ></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <label
                        for
                        class="mb-2 p-t-20 pl-1"
                        style="display:block;"
                      >Bonificación de puntos 3 imágenes de proyectos</label>
                      <!-- <div class="col-sm-4">
                                                <div class="form-group">
                                                    <select class="form-control" v-model="dataUser.bonus_point_image_project">
                                                        <option value="PENDI">Pendientex</option>
                                                        <option value="REJEC">Rechazado</option>
                                                        <option value="APPRO">Aprobado</option>                                            
                                                    </select>  
                                                </div>
                      </div>-->
                      <div class="col-sm-4">
                        <div class="form-group">
                          <p class="p-t-8">
                            <!--<button type="button" @click="putUserImageProjet()" class="btn btn-success waves-effect waves-light">Aplicar</button> -->
                            <i class="mdi mdi-content-save mdi-24px" @click="putUserImageProjet()"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row p-t-20">
                    <div class="col-md-9">
                      <div class="col-sm-3 mb-1 m-r-15">
                        <img
                          :src="dataUserImageProject[0] ? `${baseURL}${dataUserImageProject[0].image}` : 'https://dummyimage.com/150x150/5974ba/ffffff.png'"
                          class="img-rounded"
                          width="150"
                        />
                        <div>
                          <template v-if="dataUserImageProject[0]">
                            <div class="radio">
                              <input
                                type="radio"
                                name="radio1"
                                id="radio1"
                                value="true"
                                v-model="dataUserImageProject[0].approved"
                              />
                              <label for="radio1">Aprobado</label>
                            </div>
                            <div class="radio">
                              <input
                                type="radio"
                                name="radio1"
                                id="radio2"
                                value="false"
                                v-model="dataUserImageProject[0].approved"
                              />
                              <label for="radio2">Desaprobado</label>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="col-sm-3 mb-1 m-r-15">
                        <img
                          :src="dataUserImageProject[1] ? `${baseURL}${dataUserImageProject[1].image}` : 'https://dummyimage.com/150x150/5974ba/ffffff.png'"
                          class="img-rounded"
                          width="150"
                        />
                        <div>
                          <template v-if="dataUserImageProject[1]">
                            <div class="radio">
                              <input
                                type="radio"
                                name="radio2"
                                id="radio3"
                                value="true"
                                v-model="dataUserImageProject[1].approved"
                              />
                              <label for="radio3">Aprobado</label>
                            </div>
                            <div class="radio">
                              <input
                                type="radio"
                                name="radio2"
                                id="radio4"
                                value="false"
                                v-model="dataUserImageProject[1].approved"
                              />
                              <label for="radio4">Desaprobado</label>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="col-sm-3 mb-1 m-r-15">
                        <img
                          :src="dataUserImageProject[2] ? `${baseURL}${dataUserImageProject[2].image}` : 'https://dummyimage.com/150x150/5974ba/ffffff.png'"
                          class="img-rounded"
                          width="150"
                        />
                        <div>
                          <template v-if="dataUserImageProject[2]">
                            <div class="radio">
                              <input
                                type="radio"
                                name="radio3"
                                id="radio5"
                                value="true"
                                v-model="dataUserImageProject[2].approved"
                              />
                              <label for="radio5">Aprobado</label>
                            </div>
                            <div class="radio">
                              <input
                                type="radio"
                                name="radio3"
                                id="radio6"
                                value="false"
                                v-model="dataUserImageProject[2].approved"
                              />
                              <label for="radio6">Desaprobado</label>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label for class="pl-1">Bonificación de puntos por registro completado</label>

                        <span>{{ dataUser.bonus_registration_completed }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Tipo de usuario</label>
                        <span>{{ dataUser.type_user }}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Fecha de nacimiento</label>
                        <span>{{ dataUser.birthday }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Apellidos y Nombres:</label>
                        <span>{{ dataUser.last_name }} {{ dataUser.first_name }}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Nacionalidad:</label>

                        <span v-if="dataUser.country">{{ dataUser.country.name }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">DNI:</label>
                        <span>{{ dataUser.document_code }}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Movil / Whastapp</label>
                        <span>{{ dataUser.phone_movil }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Dirección para muestrarios:</label>
                        <span>{{ dataUser.address_for_samples }}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">E-mail</label>
                        <span>{{ dataUser.email }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Modalidad de prestación de servicios:</label>
                        <span>{{ dataUser.service_provision_modality_label }}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Fecha de registro:</label>
                        <span>{{ dataUser.created }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for class="pl-1">Id de tienda que lo registró:</label>
                        <span>{{ dataUser.shop_that_registered }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content user-detail">
              <div class="panel-body">
                <div class="col-md-10">
                  <div class="row">
                    <UserResumePoint :data="dataUser" />
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="col-md-10">
                  <div class="row">
                    <UserHistoricalPoint :data="dataUserHistoricalPoint" />
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content user-detail">
              <div class="panel-body">
                <div class="col-md-10">
                  <div class="row">
                    <UserResumePoint :data="dataUser" />
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="col-md-10">
                  <div class="row">
                    <UserHistoricalExchange :data="dataUserHistoricalExchange" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ref } from "vue";
import router from "@/router";
import API from "@/api";
import store from "@/store";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import Title from "@/components/Title";
import UserResumePoint from "@/components/user/UserResumePoint";
import UserHistoricalPoint from "@/components/user/UserHistoricalPoint";
import UserHistoricalExchange from "@/components/user/UserHistoricalExchange";

export default {
  name: "UserDetail",
  components: {
    Breadcrumbs,
    Title,
    UserResumePoint,
    UserHistoricalPoint,
    UserHistoricalExchange,
    Loading
  },
  setup() {
    var isLoading = ref(false);
    const fullPage = true;

    var itemProcessed = 0;
    const listImage = ref([0, 1, 2]);
    const dataCompResumePoint = ref({});
    const dataUserHistoricalPoint = ref([]);
    const dataUserHistoricalExchange = ref([]);
    const dataUser = ref({ level: {}, country: {} });
    const dataUserImageProject = ref([
      {
        image: "",
        approved: ""
      }
    ]);

    dataCompResumePoint.value = {
      accumulatedPoint: 700,
      redeemedPoints: 300,
      level: "Blue"
    };
    const baseURL = API.defaults.baseURL;
    const userId = router.currentRoute._value.params.id;
    const dataViewUserDetail = ref([
      {
        name: "Usuarios",
        nameRouter: "User",
        link: true
      },
      {        
        params: userId,
        link: false
      }
    ]);
    const dataViewTitle = ref({
      name: "Usuario"
    });
    
    const getUserImageProject = () => {
      API.get(
        "/api/user-image-project/user/" + router.currentRoute._value.params.id
      )
        .then(response => {
          //debugger
          dataUserImageProject.value = response.data;
        })
        .catch(error => {
          console.log(error.response.data);
        });
    };

    const putUserImageProjet = async () => {
      if (dataUserImageProject.value.length > 0) {
        isLoading.value = true;
        dataUserImageProject.value.forEach(element => {
          API.put("/api/user-image-project/" + element.id, element)
            .then(response => {
              isLoading.value = false;
              itemProcessed++;
              if (itemProcessed == 3) {
                itemProcessed = 0;
                Swal.fire({
                  icon: "success",
                  title: "Se guardaron las imágenes",
                  text: "Gestor de imágenes"
                });
              }
            })
            .catch(error => {
              isLoading.value = false;
              console.log(error.response.data);
            });
        });
      }
    };

    const getUserHistoricalPoint = () => {
      API.get("/api/point-activity?user=" + userId).then(response => {
        dataUserHistoricalPoint.value = response.data;
      });
    };

    const getUserHistoricalExchange = () => {
      API.get("/api/asset-exchange-activity?user=" + userId).then(response => {
        dataUserHistoricalExchange.value = response.data;
      });
    };

    const getUser = () => {
      API.get("/api/user/" + userId).then(response => {
        //debugger
        dataUser.value = response.data;
        console.log(dataUser.value, "<<<<<<<<<<<<<<<<");
      });
    };

    const actionUserUpdateStatus = c => {
      isLoading.value = true;
      store
        .dispatch("actionUserUpdateStatus", c)
        .then(response => {
          console.log("OK!");
          isLoading.value = false;
        })
        .catch(error => {
          console.log(error.response.data);
        });
    };

    return {
      isLoading,
      fullPage,
      putUserImageProjet,
      baseURL,
      store,
      listImage,
      dataUserImageProject,
      getUserImageProject,
      dataViewUserDetail,
      dataViewTitle,
      dataCompResumePoint,
      dataUserHistoricalPoint,
      getUserHistoricalPoint,
      getUserHistoricalExchange,
      dataUser,
      getUser,
      actionUserUpdateStatus,
      dataUserHistoricalExchange
    };
  },
  async created() {
    this.getUserHistoricalPoint();
    this.getUserHistoricalExchange();
    await this.getUser();
    this.getUserImageProject();
  }
};
</script>



<style scoped>
.page-content {
  /* max-width: 700px;  */
  /* margin: 32px auto;  */
  margin-left: 32px auto;
  /* padding: 32px;  */
  padding-left: 32px;
  background: #fff;
}
a {
  color: #21d4fd;
  transition: all 0.3s;
}
a:hover {
  color: #b721ff;
}

@media (min-width: 400px) and (max-width: 630px) {
  .tabbed {
    overflow-x: scroll !important;
  }
}

.tabbed {
  overflow-x: hidden; /* so we could easily hide the radio inputs */
  /* margin: 32px 0; */
  margin-left: 32px auto;
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
}

.tabbed [type="radio"] {
  /* hiding the inputs */
  display: none;
}

.tabs {
  display: flex;
  align-items: stretch;
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #ccc;
}
.tab > label {
  display: block;
  margin-bottom: -1px;
  padding: 12px 15px;
  border: 1px solid #ccc;
  background: #eee;
  color: #666;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s;
}
.tab:hover label {
  border-top-color: #333;
  color: #333;
}

.tab-content {
  display: none;
  color: #777;
}

/* As we cannot replace the numbers with variables or calls to element properties, the number of this selector parts is our tab count limit */
.tabbed [type="radio"]:nth-of-type(1):checked ~ .tabs .tab:nth-of-type(1) label,
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tabs .tab:nth-of-type(2) label,
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tabs .tab:nth-of-type(3) label,
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tabs .tab:nth-of-type(4) label,
.tabbed
  [type="radio"]:nth-of-type(5):checked
  ~ .tabs
  .tab:nth-of-type(5)
  label {
  border-bottom-color: #fff;
  /* border-top-color: #B721FF; */
  background: #fff;
  color: #222;
}

.tabbed [type="radio"]:nth-of-type(1):checked ~ .tab-content:nth-of-type(1),
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tab-content:nth-of-type(2),
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tab-content:nth-of-type(3),
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tab-content:nth-of-type(4) {
  display: block;
}
</style>