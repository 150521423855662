<template>
    <div class="container">
        <loading
            :active="isLoading"
            :can-cancel="false"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
        />
        <Breadcrumbs :data="dataViewPointManagerDetail" />
        <Title :data="dataViewTitle" />

        <div class="row">
            <div class="col-lg-10">
                <div class="panel panel-primary">
                    <div class="panel-body">
                        <div class="col-xs-6">
                            <h3>{{ $route.params.id }}</h3>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="col-xs-6">
                            <form role="form">
                                <div class="form-group">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Estado</label>
                                            <select
                                                class="form-control"
                                                v-model="pointActivityData.status"
                                            >
                                                <option value="PENDI">Pendiente</option>
                                                <option value="REJEC">Rechazado</option>
                                                <option value="APPRO">Aprobado</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Acciones</label>
                                            <p class="p-t-8">
                                                <i
                                                    class="mdi mdi-content-save mdi-18px"
                                                    @click="formPointManagerSave(pointActivityData.id, pointActivityData.status)"
                                                ></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="panel-body">
                        <div class="col-md-2">
                            <div class="panel-body">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Recibo / Factura</label>
                                    <br />
                                    <!-- <img src="https://dummyimage.com/150x150/484ea3" alt=""> -->
                                    <img
                                        :src="baseURL + '/media/' + pointActivityData.voucher"
                                        class="img-responsive"
                                        alt
                                        @click="show"
                                    />
                                    <vue-easy-lightbox
                                        :visible="visible"
                                        :imgs="items"
                                        :index="index"
                                        @hide="handleHide"
                                    ></vue-easy-lightbox>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-8">
                            <div class="panel-body">
                                <div class="col-xs-12">
                                    <div class="form-group">
                                        <div class="col-sm-10">
                                            <div class="form-group">
                                                <h4>Artículos comprados</h4>
                                                <VoucherItem :pointActivityId="router.currentRoute._value.params.id" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="panel-body">
                                <div class="col-xs-12">
                                    <div class="form-group">
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Linea de producto</label>
                                                <br />
                                                <span
                                                    v-if="pointActivityData.product_for_exchange"
                                                >{{ pointActivityData.product_for_exchange.title }}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Id de usuario</label>
                                                <br />
                                                <span>{{ pointActivityData.user.code_user }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="col-xs-12">
                                    <div class="form-group">
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Cantidad en mts.</label>
                                                <br />
                                                <span>{{ pointActivityData.meter }}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Nombres y Apellidos</label>
                                                <br />
                                                <span>{{ pointActivityData.user.first_name }} {{ pointActivityData.user.last_name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="col-xs-12">
                                    <div class="form-group">
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Fecha de solicitud</label>
                                                <br />
                                                <span>{{ pointActivityData.date_register }}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Nivel</label>
                                                <br />
                                                <span>Blue</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="col-xs-12">
                                    <div class="form-group">
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <label
                                                    for="exampleInputEmail1"
                                                >Id de tienda que registró solicitud</label>
                                                <br />
                                                <span
                                                    v-if="pointActivityData.shop"
                                                >{{ pointActivityData.shop.id }}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Tipo de usuario</label>
                                                <br />
                                                <span>{{ pointActivityData.type_user_label }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="col-xs-12">
                                    <div class="form-group">
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <label
                                                    for="exampleInputEmail1"
                                                >Detalle / Descripción</label>
                                                <br />
                                                <span>Consumo estándar</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Email</label>
                                                <br />
                                                <span>{{ pointActivityData.user.email }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="col-xs-12">
                                    <div class="form-group">
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Teléfono de contacto</label>
                                                <br />
                                                <span>989165655</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ref } from "vue";
import router from "@/router";
import Swal from "sweetalert2";
import VueEasyLightbox from "vue-easy-lightbox";
import Breadcrumbs from "@/components/Breadcrumbs";
import Title from "@/components/Title";
import API from "@/api";
import VoucherItem from "@/components/VoucherItem";

export default {
    name: "PointManager",
    components: {
        Breadcrumbs,
        Title,
        VueEasyLightbox,
        Loading,
        VoucherItem
    },
    setup() {
        var isLoading = ref(false);
        const fullPage = true;
        let pointManagerDetailId = router.currentRoute._value.params.id;
        const dataViewPointManagerDetail = ref([
            {
                name: "Gestor de puntos",
                nameRouter: "PointManager",
                link: true
            },
            {
                params: pointManagerDetailId,
                link: false
            }
        ]);
        const dataViewTitle = ref({
            name: "Gestor de puntos"
        });
        const pointActivityData = ref({
            product_for_exchange: {},
            user: {},
            shop: {}
        });
        const status = ref("");
        const baseURL = API.defaults.baseURL;
        const index = ref(0);
        const items = ref([]);
        const visible = ref(false);

        const getPointActivity = id => {
            API.get(
                "/api/point-activity/" + router.currentRoute._value.params.id
            ).then(response => {
                pointActivityData.value = response.data;
                items.value = [baseURL + "/media/" + response.data.voucher];
            });
        };

        const formPointManagerSave = (idParam, statusParam) => {
            isLoading.value = true;
            let pointActivityDataUpdate = {
                status: statusParam
            };
            API.put("/api/point-activity/" + idParam, pointActivityDataUpdate)
                .then(response => {
                    isLoading.value = false;
                    Swal.fire({
                        icon: "success",
                        title: "Se guardaron los cambios",
                        text: "Gestor de puntos"
                    });
                })
                .catch(error => {
                    isLoading.value = false;
                    console.log(error.response.data);
                });
        };
        const handleHide = () => {
            visible.value = false;
        };
        const show = () => {
            visible.value = true;
        };

        return {
            isLoading,
            fullPage,
            dataViewPointManagerDetail,
            dataViewTitle,
            pointActivityData,
            getPointActivity,
            formPointManagerSave,
            baseURL,
            index,
            items,
            visible,
            handleHide,
            show,
            router,
        };
    },
    created() {
        this.getPointActivity();
        //   this.demo2
    }
};
</script>