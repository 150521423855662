<template>
    <div class="table-responsive">            
        <table class="table">
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>Código</th>
                    <th>Membresía ID</th>
                    <th>Nombres y Apellidos</th>
                    <th>Puntos</th>                
                    <th>Estado</th>                
                    <th>Registro</th>                
                </tr>
            </thead>
            <tbody>                
                <tr v-for="(v, k) in data" :key="k">
                    <td>+</td>                
                    <td>{{ v.id }}</td>
                    <td>{{ v.user.code_user }}</td>                
                    <td>{{ v.user.first_name }} {{ v.user.last_name }}</td>                    
                    <td>{{ v.point }}</td>                    
                    <td><span :class="'label ' + getCssStatus(v.status)">{{ v.status_label }}</span></td>
                    <td>{{ v.date_register }}</td>
                </tr>
            </tbody>
            
        </table>
    </div>                    
</template>

<script>
import { getCssStatus } from "@/utils/Utils.js";
export default {
    props: {
        data: Object
    },
    setup() {
        return { getCssStatus }
    }
}
</script>