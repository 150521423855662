<template>
  <div class="container">
    <Breadcrumbs :data="dataViewAssetExchange" />
    <Title :data="dataViewTitle" />

    <div class="row">
      <div class="col-lg-10">
        <div class="panel panel-primary">
          <div class="panel-body">
            <div class="col-xs-12">
              <div class="btn-group mr-4 mb-4">
                <button
                  type="button"
                  class="btn btn-default dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Cambios estado
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <a href="#">Publicado</a>
                  </li>
                  <li>
                    <a href="#">Agotado</a>
                  </li>
                  <li>
                    <a href="#">Borrador</a>
                  </li>
                </ul>
              </div>
              <div class="btn-group mb-3 mr-4">
                <button type="button" class="btn btn-success waves-effect waves-light">Aplicar</button>
              </div>
              <div class="btn-group mb-3">
                <router-link :to="{ name: 'AssetExchangeAdd' }">
                  <button type="button" class="btn btn-success waves-effect waves-light">Nuevo canje</button>
                </router-link>
              </div>

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <div class="checkbox checkbox-single">
                          <input
                            type="checkbox"
                            id="singleCheckbox1"
                            value="option1"
                            aria-label="Single checkbox One"
                          />
                          <label></label>
                        </div>
                      </th>
                      <th>ID</th>
                      <th>Nivel</th>
                      <th>Título</th>
                      <th>Stock</th>
                      <th>Creación</th>
                      <th>Estado</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, k) in assetExchangeData" :key="k">
                      <td>
                        <div class="checkbox checkbox-single">
                          <input
                            type="checkbox"
                            id="singleCheckbox1"
                            value="option1"
                            aria-label="Single checkbox One"
                          />
                          <label></label>
                        </div>
                      </td>
                      <td>{{ v.id }}</td>
                      <td>{{ v.level.name }}</td>
                      <td>{{ v.name }}</td>
                      <td>{{ v.stock }}</td>
                      <td>{{ v.created }}</td>
                      <td>
                        <span
                          :class="'label ' + getCssStatusAssetExchange(v.status)"
                        >{{ v.status_label }}</span>
                      </td>
                      <td>
                        <router-link :to="{ name: 'AssetExchangeEdit', params:{ id: v.id } }">
                          <i class="mdi mdi-pencil mdi-18px"></i>&nbsp;
                        </router-link>
                        <!-- <i class="mdi mdi-content-save mdi-18px" @click="formShopSave(v.id, v.status)"></i> -->
                        <i class="mdi mdi-close-circle mdi-18px"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Swal from "sweetalert2";
import Breadcrumbs from "@/components/Breadcrumbs";
import { mapActions, mapState } from "vuex";
import { getCssStatusAssetExchange } from "@/utils/Utils.js";
import Title from "@/components/Title";
import API from "@/api";

export default {
  name: "AssetExchange",
  components: {
    Breadcrumbs,
    Title
  },
  setup() {
    const dataViewAssetExchange = ref([
      {
        name: "Canjes",
        nameRouter: "AssetExchange",
        link: false
      }
    ]);
    const dataViewTitle = ref({
      name: "Canjes"
    });
    const assetExchangeData = ref([]);

    const getAssetExchange = () => {
      API.get("/api/asset-exchange").then(response => {
        assetExchangeData.value = response.data;
      });
    };
    const formShopSave = (idParam, statusParam) => {
      console.log(idParam, statusParam);
      let userDataUpdate = {
        status: statusParam
      };
    };

    return {
      dataViewAssetExchange,
      dataViewTitle,
      assetExchangeData,
      getAssetExchange,
      formShopSave,
      getCssStatusAssetExchange,
      ...mapActions(["actionGetNotify"])
    };
  },
  created() {
    this.getAssetExchange();
    this.actionGetNotify();
  }
};
</script>