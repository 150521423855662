// Importamos Axios 
import axios from 'axios';
//import store from '@/store';
const store = require ('./store')
require('dotenv').config()
// Definimos la ruta local del servidor Vue JS 


export default axios.create({
    
    /* localhost */
    baseURL: process.env.VUE_APP_BASE_URL, //'http://127.0.0.1:8000',
    urlPublic: process.env.VUE_APP_URL_PUBLIC, //"http://localhost:8080",
    
    /* yourConfig: {
        headers: {
           Authorization: "Bearer " + store.state.dataToken.token
        }
     } */
    /* dev */
    /* baseURL: "http://192.81.211.181/club-barrington",
    urlPublic: "http://club-barrington.s3-website-us-east-1.amazonaws.com", */
    
    /* baseURL: "http://192.81.211.181/club-barrington",
    urlPublic: "http://192.81.211.181",
    prefixStatic: "/admin/"  */
});