<template>
    <!-- <Breadcrumbs /> -->

    <div class="container">
        
        <Breadcrumbs :data=dataViewBreadcrumbs />    
        <Title :data=dataViewTitle />

        <div class="row">
            <div class="col-lg-10">
                <div class="panel-group" id="accordion-test-2">
                    <div class="panel panel-info panel-color">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion-test-2" href="#collapseOne-1" aria-expanded="false" class="collapsed">
                                    ENCABEZADO GLOBAL                              
                                </a>
                                
                            </h4>
                        </div>
                        <div id="collapseOne-1" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <div class="col-md-6">
                                    <div class="panel panel-primary">                                    
                                        <div class="panel-body">
                                            <form role="form">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Definir encabezado global para páginas internas: </label>
                                                    <input type="file" class="form-control" placeholder="Enter email" @change="uploadImageMain($event)">
                                                    <p class="form__alert" v-if="imageConfError.errors.image">
                                                        {{ imageConfError.errors.image[0] }}
                                                    </p>
                                                </div>                                            
                                                <button type="button" class="btn btn-info waves-effect waves-light" @click="submitFormImage()">Guardar</button>
                                            </form>
                                        </div>
                                    </div> 
                                </div> 
                                <div class="col-md-6">
                                    <img :src="location + imagePath.image" class="imgHeader" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-info panel-color">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion-test-2" href="#collapseFive-6" class="collapsed" aria-expanded="false">
                                    ENCABEZADOS DE  PÁGINAS ESTÁTICAS
                                </a> 
                            </h4>
                        </div>
                        <div id="collapseFive-6" class="panel-collapse collapse">
                            <div class="panel-body">
                                <Images />
                            </div>
                        </div>
                    </div>         
                    <div class="panel panel-info panel-color">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion-test-2" href="#collapseFive-5" class="collapsed" aria-expanded="false">
                                    SLIDESHOW
                                </a> 
                            </h4>
                        </div>
                        <div id="collapseFive-5" class="panel-collapse collapse">
                            <div class="panel-body">
                                <Slideshow />
                            </div>
                        </div>
                    </div>                                
                    <div class="panel panel-info panel-color">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion-test-2" href="#collapseTwo-2" class="collapsed" aria-expanded="false">
                                    HEADER: MENÚ DE NAVEGACIÓN
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo-2" class="panel-collapse collapse">
                            <div class="panel-body">
                                 <Header />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-info panel-color">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion-test-2" href="#collapseThree-3" class="collapsed" aria-expanded="false">
                                    FOOTER: MENÚ DE NAVEGACIÓN
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree-3" class="panel-collapse collapse">
                            <div class="panel-body">
                                 <Footer />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-info panel-color">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion-test-2" href="#collapseFour-4" aria-expanded="false" class="collapsed">
                                    OTROS AJUSTES
                                </a>
                                
                            </h4>
                        </div>
                        <div id="collapseFour-4" class="panel-collapse collapse">
                            <div class="panel-body">
                                <div class="col-md-6">
                                    <div class="panel panel-primary">                                    
                                        <div class="panel-body">
                                            <form role="form" @submit.prevent>
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Disclaimer en canjes: </label>
                                                    <textarea v-model="otherConf.disclaimer_exchange" class="form-control" cols="80" rows="10"></textarea>
                                                </div>
                                                
                                                <p class="form__alert" v-if="otherConfError.errors.disclaimer_exchange">
                                                        {{ otherConfError.errors.disclaimer_exchange[0] }}
                                                </p>                                     
                                                <button type="submit" class="btn btn-info waves-effect waves-light" @click="saveOtherConf">Guardar</button>
                                            </form>
                                        </div><!-- panel-body -->
                                    </div> <!-- panel -->
                                </div> <!-- col-->
                            </div>
                        </div>
                    </div>                     
                </div>
            </div>
            
        </div>
    </div>
        
</template>

<script>

import Swal from 'sweetalert2'
import Breadcrumbs from '@/components/Breadcrumbs'
import Title from '@/components/Title'
import { mapActions, mapState } from "vuex";
import { ref } from "vue";
import store from '@/store';
import API from "@/api"

import Header from '@/components/common/header'
import Footer from '@/components/common/footer'
import Slideshow from '@/components/common/slideshow'
import Images from '@/components/common/images'

export default {
    name: 'Home',
    components: {
        Breadcrumbs,
        Title,
        Header, 
        Footer,
        Slideshow,
        Images
    },
    setup() {

        const yourConfig = API.defaults.yourConfig
        const otherConf = ref({})
        const imageConf = ref({})
        const otherConfError = ref({errors:{}})
        const imageConfError = ref({errors:{}})
        const location = process.env.VUE_APP_BASE_URL;
        const imagePath = ref({
            image: '/media/images/head_global_page_internal/no-image.jpg'
        })
        
        const dataViewBreadcrumbs = ref({
            name: 'Ajustes globales'
        })
        
        const dataViewTitle = ref({
            name: dataViewBreadcrumbs.value.name
        })

        const uploadImageMain = (event) => {                           
            imageConf.value.image = event.target.files[0];
            //imagePath.value.image = 
        }

        const getImageMain=()=>
        {
            API.get("/api/head-global-page-internal")
                .then(response => {
                    imageConf.value = response.data[0]
                    imagePath.value.image = response.data[0].image;
                })
                .catch(error => {
                    console.log('image', error.response.data) 
                    imageConfError.value = error.response.data
                })
        }

        const submitFormImage = () =>{
            const form_data = new FormData();        
            for ( var key in imageConf.value ) {
                if (!(key == 'logo' && typeof(imageConf.value[key]) == "string")){
                    form_data.append(key, imageConf.value[key]);
                }
            }           
            API.put('/api/head-global-page-internal', form_data)
            .then(response => {    
                
                Swal.fire({
                    icon: 'success',
                    title: 'Se guardaron los cambios',
                    text: 'Gestor de ajustes globales',                
                })        


            })
            .catch(error => {                                
                imageConfError.value = error.response.data                    
            })

            getImageMain();
        }

        const getOtherConf = () => {
            API.get("/api/other-conf")
                .then(response => {
                    otherConf.value = response.data                    
                })
                .catch(error => {
                    otherConfError.value = error.response.data
                })
        }

        const saveOtherConf = () => {
            //debugger
            if (otherConf.value.pk) {
                //debugger
                API.put("/api/other-conf/" + otherConf.value.pk, otherConf.value)
                    .then(response => {
                        console.log("updated!")
                        Swal.fire({
                            icon: 'success',
                            title: 'Se actualizaron los cambios',
                            text: 'Otras configuraciones',                      
                        })
                    })
                    .catch(error => {
                        otherConfError.value = error.response.data
                    })
            } else {
                API.post("/api/other-conf", otherConf.value)
                    .then(response => {
                        console.log("created!")
                        Swal.fire({
                            icon: 'success',
                            title: 'Se guardaron los cambios',
                            text: 'Otras configuraciones',                      
                        })
                    }).
                    catch(error => {
                        otherConfError.value = error.response.data
                    })
            }
        }

        return { 
            store,             
            dataViewBreadcrumbs, 
            dataViewTitle, 
            otherConf, 
            imageConf,
            imageConfError,
            otherConfError, 
            submitFormImage,
            getOtherConf, 
            saveOtherConf,
            uploadImageMain,
            getImageMain,
            location,
            imagePath,
            ...mapActions(['actionGetNotify']) 
        }
    },
    mounted() {
        this.getOtherConf();
        this.actionGetNotify();
        this.getImageMain();
    }
}
</script>

<style scoped>

.imgHeader{
    max-width: 300px;
    height: 190px;
}

</style>
