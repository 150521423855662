<template>
  <div class="container">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <Breadcrumbs :data="dataViewAssetExchange" />
    <Title :data="dataViewTitle" />
    <div class="row">
      <div class="col-lg-10">
        <div class="page-content form_asset_exchange">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for>Título</label>
                  <input class="form-control" v-model="formAssetExchange.name" />
                  <p
                    class="form__alert"
                    v-if="formAssetExchangeErrors.errors.name"
                  >{{ formAssetExchangeErrors.errors.name[0] }}</p>
                </div>
                <div class="form-group">
                  <label for>Nivel</label>
                  <select class="form-control" v-model="formAssetExchange.level">
                    <option :value="null">Seleccione un nivel</option>
                    <option :value="v.id" v-for="(v, k) in dataLevel" :key="k">{{ v.name }}</option>
                  </select>
                  <p
                    class="form__alert"
                    v-if="formAssetExchangeErrors.errors.level"
                  >{{ formAssetExchangeErrors.errors.level[0] }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for>Puntos de intercambio</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formAssetExchange.point_exchange"
                  />
                  <p
                    class="form__alert"
                    v-if="formAssetExchangeErrors.errors.point_interchange"
                  >{{ formAssetExchangeErrors.errors.point_interchange[0] }}</p>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for>Stock</label>
                  <input type="text" class="form-control" v-model="formAssetExchange.stock" />
                  <p
                    class="form__alert"
                    v-if="formAssetExchangeErrors.errors.stock"
                  >{{ formAssetExchangeErrors.errors.stock[0] }}</p>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for>Estado</label>
                  <select class="form-control" v-model="formAssetExchange.status">
                    <option value="null">Seleccione un estado</option>
                    <option value="DRAFT">Borrador</option>
                    <option value="SOLDO">Agotado</option>
                    <option value="PUBLI">Publicado</option>
                  </select>
                  <p
                    class="form__alert"
                    v-if="formAssetExchangeErrors.errors.status"
                  >{{ formAssetExchangeErrors.errors.status[0] }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for>Suba imagen del canje</label>
                  <input
                    type="file"
                    accept="image/*"
                    class="form__field"
                    @change="uploadImage($event)"
                  />
                  <p
                    class="form__alert"
                    v-if="formAssetExchangeErrors.errors.image"
                  >{{ formAssetExchangeErrors.errors.image[0] }}</p>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for>&nbsp;</label>
                  <!-- <img src="https://dummyimage.com/150x150/5974ba/ffffff.png" class="img-rounded" alt=""> -->
                  <img
                    :src="formAssetExchange.image ? formAssetExchange.image : 'https://dummyimage.com/150x150/5974ba/ffffff.png'"
                    class="img-rounded"
                    alt
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for>Detalle o descripción del canje</label>
                  <textarea
                    class="form-control"
                    cols="30"
                    rows="10"
                    v-model="formAssetExchange.description"
                  ></textarea>
                  <p
                    class="form__alert"
                    v-if="formAssetExchangeErrors.errors.description"
                  >{{ formAssetExchangeErrors.errors.description[0] }}</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <button
                  type="submit"
                  class="btn btn-success waves-effect waves-light"
                  @click="submitFormAssetExchange()"
                >Guardar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ref } from "vue";
import router from "@/router";
import API from "@/api";
import { mapActions } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import Title from "@/components/Title";
/* import ShopResumePoint from "@/components/shop/ShopResumePoint";
import ShopHistoricalPoint from "@/components/shop/ShopHistoricalPoint"; */

export default {
  name: "AssetExchangeAdd",
  components: {
    Breadcrumbs,
    Title,
    Loading
  },
  setup() {
    var isLoading = ref(false);
    const fullPage = true;

    const vueAppBaseUrl = process.env.VUE_APP_BASE_URL;
    const formAssetExchange = ref({
      errors: [],
      name: "",
      status: null,
      level: null,
      point_exchange: 0,
      stock: 0,
      image: "",
      description: ""
    });
    const formAssetExchangeErrors = ref({ errors: {} });
    const assetExchangeId = router.currentRoute._value.params.id;
    const dataViewAssetExchange = ref([
      {
        name: "Canjes",
        nameRouter: "AssetExchange",
        link: true
      },
      {        
        params: assetExchangeId,
        link: false
      }
    ]);
    const dataViewTitle = ref({
      name: "Canjes"
    });
    const dataLevel = ref([]);

    const uploadImage = event => {
      formAssetExchange.value.image = event.target.files[0];
    };
    const getLevel = () => {
      API.get("/api/level").then(response => {
        dataLevel.value = response.data;
      });
    };

    console.log(assetExchangeId, ">>>");

    const getAssetExchange = assetExchangeId => {
      API.get("/api/asset-exchange/" + assetExchangeId).then(response => {
        formAssetExchange.value = response.data;
        formAssetExchange.value.level = response.data.level.id;
        if (response.data.image) {
          formAssetExchange.value.image = vueAppBaseUrl + response.data.image;
        }
      });
    };

    const submitFormAssetExchange = () => {
      isLoading.value = true;
      const form_data = new FormData();
      //debugger
      for (var key in formAssetExchange.value) {
        if (
          !(key == "image" && typeof formAssetExchange.value[key] == "string")
        ) {
          form_data.append(key, formAssetExchange.value[key]);
        }
      }
      //debugger
      if (assetExchangeId) {
        API.put("/api/asset-exchange/" + assetExchangeId, form_data)
          .then(response => {
            isLoading.value = false;
            router.push({ name: "AssetExchange" });
          })
          .catch(error => {
            formAssetExchangeErrors.value = error.response.data;
          });
      } else {
        API.post("/api/asset-exchange", form_data)
          .then(response => {
            isLoading.value = false;
            router.push({ name: "AssetExchange" });
          })
          .catch(error => {
            isLoading.value = false;
            formAssetExchangeErrors.value = error.response.data;
          });
      }
    };

    return {
      isLoading,
      fullPage,
      vueAppBaseUrl,
      assetExchangeId,
      getAssetExchange,
      dataLevel,
      dataViewAssetExchange,
      dataViewTitle,
      getLevel,
      formAssetExchange,
      formAssetExchangeErrors,
      submitFormAssetExchange,
      uploadImage,
      ...mapActions(["actionShopUpdateStatus"])
    };
  },
  created() {
    this.getLevel();
    console.log(this.assetExchangeId, "CON", process.env);
    if (this.assetExchangeId) {
      this.getAssetExchange(this.assetExchangeId);
    }
  }
};
</script>
