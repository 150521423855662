<template>
    <div class="container">
        
        <Breadcrumbs :data=dataViewBreadcrumbs />    
        <Title :data=dataViewTitle />

        <div class="row">
            <div class="col-lg-10">
                <div class="panel-group" id="accordion-test-2">
                    <div class="panel panel-info panel-color">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion-test-2" href="#collapseOne-2" aria-expanded="false" class="collapsed">
                                    BONIFICACIONES ESPECIALES
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne-2" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <div class="col-md-10">
                                    <div class="panel panel-primary">                                    
                                        <div class="panel-body">
                                            <form class="form-horizontal left" role="form" @submit.prevent>
                                                <div class="form-group">
                                                    <label class="col-md-9 control-label">Puntos asignados a usuarios por completar totalidad de campos de registro:</label>
                                                    <div class="col-md-1">
                                                        <input type="number" min="0" step="1" class="form-control" v-model="dataConf[0].bonus_registration_completed">
                                                    </div>
                                                    <button type="submit" @click="saveBonusRegistrationCompleted()" class="btn btn-success waves-effect waves-light m-l-10">Guardar</button>
                                                </div>
                                            </form>
                                            <form class="form-horizontal left" role="form" @submit.prevent>
                                                <div class="form-group">
                                                    <label class="col-md-9 control-label" for="example-email">Puntos asignados a usuarios por subir 3 imágenes de últimos proyectos:</label>
                                                    <div class="col-md-1">
                                                        <input type="number" min="0" step="1" class="form-control" v-model="dataConf[0].bonus_point_image_project">
                                                    </div>
                                                    <button type="submit" @click="saveBonusImageProject()" class="btn btn-success m-l-10">Guardar</button>
                                                </div>
                                                
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="panel">
                                        <div class="panel-body">
                                            <p>Bonificación global para todos los usuarios por motivos excepcionales.</p>
                                            <p>(Asignaciones especificas deben ser gestionadas desde el menú usuario)</p>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                <div class="col-md-12">
                                    <div class="panel panel-primary">                                    
                                        <div class="panel-body">
                                            <form role="form" @submit.prevent>
                                                <div class="form-group">
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Descripción o motivo de la bonificación</label>
                                                            <input type="text" class="form-control" v-model="formBonoSpecial.bonus_description">
                                                        </div>
                                                        
                                                        <p class="form__alert" v-if="formBonoSpecialError.errors.bonus_description">
                                                            {{ formBonoSpecialError.errors.bonus_description[0] }}
                                                        </p>
                                                        

                                                    </div>
                                                    
                                                    <div class="col-sm-2">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Bono de puntos</label>
                                                            <input type="number" min="0" step="1" class="form-control" v-model="formBonoSpecial.bonus_point">                   
                                                        </div>
                                                        <p class="form__alert" v-if="formBonoSpecialError.errors.bonus_point">
                                                            {{ formBonoSpecialError.errors.bonus_point[0] }}
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="col-sm-2">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Fecha de abono</label>
                                                            <!-- <input type="text" class="form-control" v-model="formBonoSpecial.bonus_date" placeholder="AAAA-MM-DD"> -->
                                                            
                                                                <Datepicker 
                                                                class="form-control"
                                                                v-model="formBonoSpecial.bonus_date" 
                                                                />
                                                            
                                                            
                                                            <p class="form__alert" v-if="formBonoSpecialError.errors.bonus_date">
                                                                {{ formBonoSpecialError.errors.bonus_date[0] }}
                                                            </p>
                                                        </div>
                                                    
                                                    </div>
                                                    <div class="col-sm-1">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">&nbsp;</label>
                                                            <button type="submit" class="btn btn-success waves-effect waves-light m-l-10" @click="submitFormBonoSpecial()">Crear</button>  
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                                                            
                                            </form>

                                        </div><!-- panel-body -->
                                    </div> <!-- panel -->
                                </div> <!-- col-->
                                <div class="col-md-10">
                                    
                                    <div class="panel panel-primary">                                    
                                        <div class="panel-body">
                                            <div class="col-xs-12">
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Descripción o motivo</th>
                                                                <th>Bono de puntos</th>
                                                                <th>Fecha</th>
                                                                <th>Acciones</th>                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(v, k) in dataBonoSpecial" :key="k">
                                                                <template v-if="!v.editMode">
                                                                    <td>{{ v.bonus_description }}</td>
                                                                    <td>{{ v.bonus_point }}</td>
                                                                    <td>{{ v.bonus_date }}</td>
                                                                </template>
                                                                <template v-else>
                                                                    <td><input type="text" class="form-control" v-model="v.bonus_description"></td>
                                                                    <td><input type="text" class="form-control" v-model="v.bonus_point"></td>
                                                                    <td><input type="text" class="form-control" v-model="v.bonus_date"></td>
                                                                    
                                                                </template>
                                                                <td>
                                                                    <i v-if="!v.editMode" @click="editBonoSpecial(v)" class="mdi mdi-pencil mdi-18px"></i>&nbsp;
                                                                    <i v-else @click="updateBonoSpecial(v)" class="mdi mdi-content-save mdi-18px"></i>&nbsp;
                                                                    <i @click="deleteBonoSpecial(v)" class="mdi mdi-close-circle-outline mdi-18px"></i>
                                                                </td>
                                                            </tr>                                                           
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-info panel-color">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion-test-2" href="#collapseTwo-2" class="collapsed" aria-expanded="false">
                                    PUNTOS: CONFIGURACIÓN DE EQUIVALENCIAS
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo-2" class="panel-collapse collapse">
                            <div class="panel-body">
                                <div class="col-md-10">                                    
                                    <div class="panel panel-primary">                                                                            
                                        <div class="panel-body">                                            
                                            <div class="col-md-5">
                                                <div class="btn-group mb-3">
                                                    <button @click="formAssetForExchange" type="button" class="btn btn-success waves-effect waves-light">Nuevo producto</button>
                                                </div>
                                            </div>
                                            
                                            <div class="col-xs-12">
                                                <div class="btn-group mr-4 mb-3">
                                                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Acciones en lote <span class="caret"></span>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li><a href="#">Eliminar</a></li>
                                                        <li><a href="#">Editar puntos</a></li>                                                        
                                                    </ul>
                                                    
                                                    
                                                </div>
                                                <div class="btn-group mb-3">
                                                    <button type="button" class="btn btn-success waves-effect waves-light">Aplicar</button>
                                                </div>
                                                
                                                
                                                <div class="row">
                                                    <form role="form">
                                                        <div class="form-group">
                                                            <!-- <div class="col-sm-2">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Código</label>
                                                                    <input type="text" class="form-control" v-model="formEquivalence.code">
                                                                </div>

                                                            </div> -->
                                                            
                                                            <div class="col-sm-3">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Línea de artículo</label>
                                                                    <select class="form-control" v-model="formEquivalence.item">
                                                                        <option :value=null disabled>Seleccione un item</option>
                                                                        <option :value="v.id" v-for="(v, k) in dataProductForExchangeItem" :key="k">{{ v.title }}</option>
                                                                    </select>
                                                                    <!-- <input type="text" class="form-control" v-model="formEquivalence.item">-->
                                                                </div>
                                                            
                                                            </div>
                                                            <div class="col-sm-1">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">BL</label>
                                                                    <input type="text" class="form-control" v-model="formEquivalence.level[1]">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-1">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">BR</label>
                                                                    <input type="text" class="form-control" v-model="formEquivalence.level[2]">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-1">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">PL</label>
                                                                    <input type="text" class="form-control" v-model="formEquivalence.level[3]">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-1">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">OR</label>
                                                                    <input type="text" class="form-control" v-model="formEquivalence.level[4]">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-1">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">DI</label>
                                                                    <input type="text" class="form-control" v-model="formEquivalence.level[5]">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-2">
                                                                <div class="form-group">                                                                    
                                                                    <button type="button" @click="submitFormEquivalence" class="btn btn-success waves-effect waves-light m-t-25">Agregar</button>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                                                                    
                                                    </form>
    
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <div class="checkbox checkbox-single">
                                                                        <input type="checkbox" id="singleCheckbox1" value="option1" aria-label="Single checkbox One">
                                                                        <label></label>
                                                                    </div>
                                                                </th>
                                                                <th>Código</th>
                                                                <th>Item</th>
                                                                <th>BL</th>
                                                                <th>BR</th>
                                                                <th>PL</th>
                                                                <th>OR</th>
                                                                <th>DI</th>
                                                                <th>Acciones</th>                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(v, k) in dataPointForExchange" :key="k">
                                                                <td>
                                                                    <div class="checkbox checkbox-single">
                                                                        <input type="checkbox" id="singleCheckbox1" value="option1" aria-label="Single checkbox One">
                                                                        <label></label>
                                                                    </div>
                                                                </td>
                                                                <td>{{ v.code}}</td>
                                                                <td>{{ v.name }}</td>
                                                                <template v-if="!v.editMode">
                                                                    <td v-for="(v2, k2) in v.items" :key="k2">{{ v2.point }}</td>
                                                                </template>
                                                                <template v-else>
                                                                    <td v-for="(v2, k2) in v.items" :key="k2">
                                                                        <!-- x{{ v2.point }}x -->
                                                                        <input type="number" v-model="v2.point" class="form-control">
                                                                    </td>
                                                                </template>
                                                                
                                                                <td>
                                                                    <i v-if="!v.editMode" @click="editUpdateFormEquivalence(v)" class="mdi mdi-pencil mdi-18px"></i>&nbsp;
                                                                    <i v-else @click="submitUpdateFormEquivalence(v)" class="mdi mdi-content-save mdi-18px"></i>&nbsp;
                                                                    <i @click="deleteFormEquivalence(v)" class="mdi mdi-close-circle-outline mdi-18px"></i>
                                                                </td>
                                                            </tr>
                                                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-info panel-color">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion-test-2" href="#collapseThree-2" class="collapsed" aria-expanded="false">
                                    NIVELES: CONFIGURACIÓN DE HITOS
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree-2" class="panel-collapse collapse">
                            <div class="panel-body">
                                <form class="form-horizontal" role="form">
                                    <div class="form-group" v-for="(v, k) in dataLevel" :key="k">
                                        <label for="inputEmail3" class="col-sm-3 control-label">Nivel {{ v.name }}</label>
                                        <div class="col-sm-2">
                                            <span>Desde:</span>
                                            <input type="hidden" v-model="v.id">
                                            <input type="number" class="form-control" v-model="v.from_point">
                                        </div>
                                    </div>
                                    <!-- <div class="form-group">
                                        <label for="inputEmail3" class="col-sm-3 control-label">Nivel bronce</label>
                                        <div class="col-sm-1">
                                            <span>Desde:</span>
                                            <input type="number" class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputEmail3" class="col-sm-3 control-label">Nivel plata</label>
                                        <div class="col-sm-1">
                                            <span>Desde:</span>
                                            <input type="number" class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputEmail3" class="col-sm-3 control-label">Nivel oro</label>
                                        <div class="col-sm-1">
                                            <span>Desde:</span>
                                            <input type="number" class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputEmail3" class="col-sm-3 control-label">Nivel diamante</label>
                                        <div class="col-sm-1">
                                            <span>Desde:</span>
                                            <input type="number" class="form-control">
                                        </div>
                                    </div> -->
                                    <div class="form-group m-b-0">
                                        <div class="col-sm-offset-3 col-sm-9">
                                            <button type="button" class="btn btn-info waves-effect waves-light" @click="updateDataLevel">Guardar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import { ref } from "vue";
import Swal from 'sweetalert2'
import Datepicker from 'vue3-datepicker'
import { mapActions, mapState } from "vuex";
import Breadcrumbs from '@/components/Breadcrumbs'
import Title from '@/components/Title'
import API from "@/api";

const picked = ref(new Date())

export default {
    name: "LevelPoint",
    components: {
        Breadcrumbs,
        Title,        
        Datepicker
    },
    setup() {

        const dataBonoSpecial = ref([])
        const formBonoSpecial = ref({
            bonus_description: "",
            bonus_point: "",
            bonus_date: ""
        })
        const editMode = ref(false)

        const formBonoSpecialError = ref({errors:{}})

        const dataConf = ref([
            {
                bonus_registration_completed: 0, 
                bonus_point_image_project:0 
            }
        ])

        const formEquivalence = ref({            
            level: {1: "0", 2: "0", 3: "0", 4: "0", 5:"0"},
            item: null,
            code: ""
        })
        const dataLevel = ref([])

        const dataViewBreadcrumbs = ref({
            name: 'CLub Barrington / Niveles y puntos'
        })
        const dataViewTitle = ref({
            name: 'Niveles y puntos'
        })

        const dataProductForExchangeItem = ref([])
        const dataPointForExchange = ref([])

        const editBonoSpecial = (obj) => {                        
            obj['editMode'] = true
        }
        const updateBonoSpecial = (obj) => {
            //debugger
            API.put("/api/bono-special/" + obj.pk, obj)
                .then(response => {
                    obj['editMode'] = false
                    console.log('updated!')
                })
                .catch(error => {
                    console.log(error.response.data, 'xxxxxxxxx')
                })                

        }

        const deleteBonoSpecial = (obj) => {
            Swal.fire({
                title: '¿Desea eliminar la bonificación especial?',                
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: `SI`,
                
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        console.log(obj, 'ELEME')                        
                        await API.delete("/api/bono-special/" + obj.pk)                        
                        getBonoSpecial()
                    }
                })
        }
        
        const editUpdateFormEquivalence = (obj) => {                        
            obj['editMode'] = true
        }
        
        const submitUpdateFormEquivalence = async (obj) => {            
            obj.items.forEach(element => {                
                let payload = { point: element.point }
                API.put("/api/point-for-exchange/" + element.pk, payload)
                    .then(response => {
                        console.log('updated!')
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    })                
            });
            obj['editMode'] = false            

        }

        const deleteFormEquivalence = (obj) => {
            Swal.fire({
                title: '¿Desea eliminar la equivalencia?',                
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: `SI`,
                
                }).then(async (result) => {
                    if (result.isConfirmed) {                        
                        await API.delete("/api/product-for-exchange/" + obj.pk)                        
                        getPointForExchange()
                    }
                })
        }

        const submitFormBonoSpecial = () => {                        
            //debugger
            formBonoSpecial.value.bonus_date = formBonoSpecial.value.bonus_date.toISOString().split('T')[0]
            API.post("/api/bono-special", formBonoSpecial.value)
                
                .then(response => {
                    formBonoSpecial.value = {}
                    formBonoSpecialError.value = {errors: {}}       
                    getBonoSpecial()             
                    Swal.fire({
                        icon: 'success',
                        title: 'Se guardaron los cambios',
                        text: 'Bono especial',                        
                    })
                })
                .catch(error => {                    
                    formBonoSpecialError.value = error.response.data
                })
        }

        const getBonoSpecial = () => {
            API.get("/api/bono-special")
                .then(response => {
                    dataBonoSpecial.value = response.data
                })
        }

        var dataTempConfReg = []
        const saveBonusRegistrationCompleted = () => {                        
            API.get("/api/conf")
                .then(response => {                    
                    dataTempConfReg = response.data                    
                    /*obtengo la data del server y no del input para saber realmente si hubo data y con ello saber si 
                    usar post o put */
                    //debugger                    
                    if (dataTempConfReg.length > 0){                        
                        let payload = { bonus_registration_completed: dataConf.value[0].bonus_registration_completed }
                        API.put("/api/conf", payload)
                            .then(response => {
                                console.log('update!')
                            })
                            .catch(error => {
                                console.log(error.response.data)
                            })
                    } else {
                        let payload = { bonus_registration_completed: dataConf.value[0].bonus_registration_completed }
                        API.post("/api/conf", payload)
                            .then(response => {
                                console.log('created!')
                            })
                            .catch(error => {
                                console.log(error.response.data)
                            })                        
                    }                    
                    Swal.fire({
                        icon: 'success',
                        title: 'Se guardaron los cambios',
                        text: 'Niveles y puntos',                        
                    })                            
                })                        
        }

        var dataTempConfImg = []
        const saveBonusImageProject = () => {                        
            API.get("/api/conf")
                .then(response => {                    
                    dataTempConfImg = response.data                    
                    /*obtengo la data del server y no del input para saber realmente si hubo data y con ello saber si 
                    usar post o put */
                    //debugger                    
                    if (dataTempConfImg.length > 0){                        
                        let payload = { bonus_point_image_project: dataConf.value[0].bonus_point_image_project }
                        API.put("/api/conf", payload)
                            .then(response => {
                                console.log('update!')
                            })
                            .catch(error => {
                                console.log(error.response.data)
                            })
                    } else {
                        let payload = { bonus_point_image_project: dataConf.value[0].bonus_point_image_project }
                        API.post("/api/conf", payload)
                            .then(response => {
                                console.log('created!')
                            })
                            .catch(error => {
                                console.log(error.response.data)
                            })                        
                    }    
                    Swal.fire({
                        icon: 'success',
                        title: 'Se guardaron los cambios',
                        text: 'Niveles y puntos',                        
                    })                                            
                })                        
        }

        const getConf = () => {

            API.get("/api/conf")
                .then(response => {
                    //debugger
                    if (response.data.length > 0) {
                        dataConf.value = response.data
                        
                    }
                    
                })
        }
        
        const getDataProductForExchange = () => {
            API.get("/api/product-for-exchange")
                .then(response => {
                    //debugger;
                    dataProductForExchangeItem.value = response.data
                })
        }
        const submitFormEquivalence = async () => {
            
            for ( var key in formEquivalence.value.level ) {
                //console.log(formEquivalence.value.item)
                let data = {product_for_exchange: formEquivalence.value.item, level: key, point: formEquivalence.value.level[key]}                
                await API.post("/api/point-for-exchange", data)
                    .then(response => {
                        console.log('created!')
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    })

            }
            getPointForExchange()
            console.log('jala!')

        }
        const getPointForExchange = () => {
            API.get("/api/point-for-exchange")
                .then(response => {
                    //debugger
                    dataPointForExchange.value = response.data
                })
            }            
        
        const getLevel = () => {
            API.get("/api/level")
                .then(response => {
                    dataLevel.value = response.data
                })
        }

        const updateDataLevel = () => {
            //console.log(dataLevel, 'level')
            var len = dataLevel.value.length
            var iDataLevel = 0            
            dataLevel.value.map(obj => {                
                let payload = { from_point: obj.from_point }                
                API.put("/api/level/" + obj.id, payload)
                    .then(response => {                        
                        iDataLevel++                        
                        if (len == iDataLevel) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Se guardaron los cambios',
                                text: 'Hitos',                        
                            })                            
                        }
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    })
            })
        }                

        const formAssetForExchange = () => {
            Swal.fire({
                title: 'Nuevo producto',
                html: `<input type="text" id="code" class="form-control" placeholder="Código">
                <input type="text" id="title" class="form-control" placeholder="Nombre">`,
                confirmButtonText: 'Guardar',
                focusConfirm: false,
                preConfirm: () => {
                    const code = Swal.getPopup().querySelector('#code').value
                    const title = Swal.getPopup().querySelector('#title').value
                    if (!code || !title) {
                    Swal.showValidationMessage(`Agregue un código o nombre`)
                    }
                    return { code: code, title: title }
                }
                }).then((result) => {
                    
                    API.post("/api/product-for-exchange", result.value)
                        .then(response => {
                            
                            getDataProductForExchange();
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        })
                /* Swal.fire(`
                    Login: ${result.value.code}
                    Password: ${result.value.password}
                `.trim()) */
                })
        }

        return { 
            deleteBonoSpecial,
            editBonoSpecial,
            updateBonoSpecial,
            deleteFormEquivalence,
            editUpdateFormEquivalence,
            submitUpdateFormEquivalence,
            dataLevel,
            dataViewBreadcrumbs, 
            dataViewTitle, 
            formEquivalence, 
            submitFormEquivalence, 
            getDataProductForExchange, 
            dataProductForExchangeItem,
            dataPointForExchange,
            getPointForExchange,
            getLevel,
            updateDataLevel,
            formAssetForExchange,                        
            getConf,
            saveBonusRegistrationCompleted,
            saveBonusImageProject,                                                                                      
            dataConf,
            formBonoSpecial,
            formBonoSpecialError,
            submitFormBonoSpecial,
            dataBonoSpecial,
            getBonoSpecial,
            picked,
            ...mapActions(['actionGetNotify']) 
        }
        
    },
    async mounted() {
        this.getDataProductForExchange();
        this.getPointForExchange();
        this.getLevel();
        this.getConf();
        this.getBonoSpecial();
        this.actionGetNotify();
    }

}
</script>
