<template>
    <div>
        <form role="form" @submit.prevent>
            <div class="form-group">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Código</label>
                        <input type="text" class="form-control" v-model="formColor.code" />
                    </div>
                    <p
                        class="form__alert"
                        v-if="formColorError.errors.code"
                    >{{ formColorError.errors.code[0] }}</p>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Nombre</label>
                        <input type="text" class="form-control" v-model="formColor.name" />
                    </div>
                    <p
                        class="form__alert"
                        v-if="formColorError.errors.bonus_point"
                    >{{ formColorError.errors.bonus_point[0] }}</p>
                </div>
                <div class="col-sm-1">
                    <div class="form-group">
                        <label for="exampleInputEmail1">&nbsp;</label>
                        <button
                            type="submit"
                            class="btn btn-success waves-effect waves-light m-l-10"
                            @click="submitFormColor()"
                        >Crear</button>
                    </div>
                </div>
            </div>
        </form>
        <div class="col-xs-12">
            <div class="table-responsive">
                
                <table class="table">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nombre</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v, k) in dataColor" :key="k">
                            <template v-if="!v.editMode">
                                <td>{{ v.code }}</td>
                                <td>{{ v.name }}</td>
                            </template>
                            <template v-else>
                                <td>
                                    <input type="text" v-model="v.code" class="form-control" />
                                </td>
                                <td>
                                    <input type="text" v-model="v.name" class="form-control" />
                                </td>
                            </template>

                            <td>
                                <i
                                    v-if="!v.editMode"
                                    @click="editColor(v)"
                                    class="mdi mdi-pencil mdi-18px"
                                ></i>&nbsp;
                                <i
                                    v-else
                                    @click="updateColor(v)"
                                    class="mdi mdi-content-save mdi-18px"
                                ></i>&nbsp;
                                <i
                                    @click="deleteColor(v)"
                                    class="mdi mdi-close-circle-outline mdi-18px"
                                ></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import Swal from "sweetalert2";
import store from "@/store";
export default {
    name: "Color",
    setup() {
        const formColor = ref({
            name: null,
            code: ""
        });
        const formColorError = ref({ errors: {} });
        const dataColor = ref([]);
        const editMode = ref(false);

        const editColor = obj => {
            obj["editMode"] = true;
        };

        const submitFormColor = () => {
            store
                .dispatch("postColor", formColor.value)
                .then(response => {
                    //debugger;
                    formColor.value = {};
                    getColor();
                    Swal.fire({
                        icon: "success",
                        title: "Se guardaron los cambios",
                        text: "Color"
                    });
                })
                .catch(error => {
                    formColorError.value = error.response.data;
                });
        };
        const getColor = () => {
            store.dispatch("getColor").then(response => {
                dataColor.value = response.data;
            });
        };
        const updateColor = obj => {        
            store
                .dispatch("putColor", obj)
                .then(response => {
                    obj["editMode"] = false;
                    console.log("updated!");
                })
                .catch(error => {
                    console.log(error.response.data, "xxxxxxxxx");
                });
        };

        const deleteColor = (obj) => {
            Swal.fire({
                title: '¿Desea eliminar este color?',                
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: `SÍ`,
                
                }).then(async (result) => {
                    if (result.isConfirmed) {                        
                        console.log(obj, 'ELEME')                        
                        await store.dispatch("deleteColor", obj)                        
                        getColor()
                    }
                })
        }

        return {
            submitFormColor,
            deleteColor,
            updateColor,
            dataColor,
            formColor,
            formColorError,
            editMode,
            editColor,
            getColor
        };
    },
    mounted() {
        this.getColor();
    }
};
</script>