<template>
  <div class="container">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="fullPage" />
    <Breadcrumbs :data="dataViewPointManager" />
    <Title :data="dataViewTitle" />

    <div class="row">
      <div class="col-lg-10">
        <div class="panel panel-primary">
          <div class="panel-body">
            <form action id="form-toolbar-user" @submit.prevent>
              <div class="col-xs-12">
                <div class="col-sm-2 mr-4">
                  <div class="btn-group mr-1 mb-3">
                    <button
                      type="button"
                      class="btn btn-default dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Cambios masivo
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a href="#">Pendiente</a>
                      </li>
                      <li>
                        <a href="#">Aprobado</a>
                      </li>
                      <li>
                        <a href="#">Rechazado</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="btn-group mr-4 mb-3 m-l-10">
                    <button type="button" class="btn btn-success waves-effect waves-light">Aplicar</button>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="btn-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="queryStringObj.query"
                      @keyup.enter="searchPointManager()"
                      placeholder="Nombres o apellidos de usuario a buscar..."
                    />
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="btn-group mb-3">
                    <select name id class="form-control" v-model="queryStringObj.status">
                      <option value>Seleccione estado</option>
                      <option value="PENDI">Pendiente</option>
                      <option value="REJEC">Rechazado</option>
                      <option value="SUSPE">Suspendido</option>
                      <option value="APPRO">Aprobado</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="btn-group mb-3">
                    <button
                      type="button"
                      @click="searchPointManager()"
                      class="btn btn-success waves-effect waves-light"
                    >Buscar</button>
                  </div>
                </div>
              </div>
            </form>
            <div class="col-xs-12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <div class="checkbox checkbox-single">
                          <input
                            type="checkbox"
                            id="singleCheckbox1"
                            value="option1"
                            aria-label="Single checkbox One"
                          />
                          <label></label>
                        </div>
                      </th>
                      <th>ID</th>
                      <th>ID Tienda Reg.</th>
                      <th>ID Usuario</th>
                      <th>Nombres y Apellidos</th>
                      <th>Puntos req.</th>
                      <th>Fecha registro</th>
                      <th>Estado</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, k) in pointActivityData" :key="k" :class="setColorRow(v.status)">
                      <td>
                        <div class="checkbox checkbox-single">
                          <input
                            type="checkbox"
                            id="singleCheckbox1"
                            value="option1"
                            aria-label="Single checkbox One"
                          />
                          <label></label>
                        </div>
                      </td>
                      <td>{{ v.id }}</td>
                      <td><template v-if="v.shop">{{ v.shop.business_name }}</template></td>
                      <td>{{ v.user.code_user }}</td>
                      <td>{{ v.user.first_name }} {{ v.user.last_name }}</td>
                      <td>{{ v.point }}</td>
                      <td>{{ v.date_register }}</td>
                      <td>
                        <select class="form-control" v-model="v.status">
                          <option value>Seleccione estado</option>
                          <option value="PENDI">Pendiente</option>
                          <option value="REJEC">Rechazado</option>
                          <option value="APPRO">Aprobado</option>
                        </select>
                      </td>
                      <td>
                        <router-link :to="{ name: 'PointManagerDetail', params:{ id: v.id } }">
                          <i class="mdi mdi-magnify mdi-18px"></i>&nbsp;
                        </router-link>
                        <i
                          class="mdi mdi-content-save mdi-18px"
                          @click="formPointManagerSave(v.id, v.status)"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-xs-12 text-right">
              <Pagination
                v-model="page"
                :records="records"
                :per-page="limit"
                @paginate="next()"
                :options="options"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "v-pagination-3";
import MyPagination from "@/MyPagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ref } from "vue";
import Swal from "sweetalert2";
import store from "@/store";
import Breadcrumbs from "@/components/Breadcrumbs";
import { mapActions, mapState } from "vuex";
import {
  setColorRow,
  setQueryString,
  getFullQueryString
} from "@/utils/Utils.js";
import Title from "@/components/Title";
import API from "@/api";

export default {
  name: "PointManager",
  components: {
    Breadcrumbs,
    Pagination,
    Title,
    Loading
  },
  setup() {
    var isLoading = ref(false);
    const fullPage = true;

    var paginateString = ref("");
    const page = ref(1);
    const limit = ref(10);
    const records = ref(0);

    var options = ref({
      template: MyPagination
    });

    var queryStringObj = ref({
      status: "",
      query: ""
    });
    const next = () => {
      getPointActivity();
    };
    const dataViewPointManager = ref([
      {
        name: "Gestor de puntos",
        nameRouter: "PointManager",
        link: false
      }
    ]);
    const dataViewTitle = ref({
      name: "Gestor de puntos"
    });
    const pointActivityData = ref([
      {
        shop: {},
        user: {}
      }
    ]);

    const getPointActivity = () => {
      let gfqs = getFullQueryString({
        queryStringObjParam: queryStringObj.value,
        limitParam: limit.value,
        pageParam: page.value
      });
      store.dispatch("getQueryPointActivity", gfqs).then(response => {
        pointActivityData.value = response.data.results;
        records.value = response.data.count;
      });
    };
    const formPointManagerSave = (idParam, statusParam) => {
      isLoading.value = true;
      let pointActivityDataUpdate = {
        status: statusParam
      };
      API.put("/api/point-activity/" + idParam, pointActivityDataUpdate)
        .then(response => {
          isLoading.value = false;
          Swal.fire({
            icon: "success",
            title: "Se guardaron los cambios",
            text: "Gestor de puntos"
          });
        })
        .catch(error => {
          isLoading.value = false;
          console.log(error.response.data);
        });
    };

    const searchPointManager = () => {
      //debugger
      let gfqs = getFullQueryString({
        queryStringObjParam: queryStringObj.value
      });
      store
        .dispatch("getQueryPointActivity", gfqs)
        .then(response => {
          pointActivityData.value = response.data.results;
          records.value = response.data.count;
        })
        .catch(error => {
          console.log(error.response.data);
        });
    };

    return {
      isLoading,
      fullPage,
      dataViewPointManager,
      dataViewTitle,
      pointActivityData,
      getPointActivity,
      formPointManagerSave,
      setColorRow,
      searchPointManager,
      queryStringObj,
      page,
      next,
      records,
      limit,
      options,
      ...mapActions(["actionGetNotify"])
    };
  },
  created() {
    this.getPointActivity();
    this.actionGetNotify();
  }
};
</script>